import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "py-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.related_graphs, (graph, k) => {
      return (_openBlock(), _createElementBlock("div", {
        key: k,
        class: "px-4 flex"
      }, [
        _createElementVNode("div", {
          class: "flex-auto truncate cursor-pointer",
          onClick: ($event: any) => (_ctx.changeGraph(graph))
        }, [
          _createElementVNode("span", null, _toDisplayString(graph.data.message), 1)
        ], 8, _hoisted_2),
        _createElementVNode("div", {
          class: "flex-none w-1/5 truncate cursor-pointer",
          onClick: ($event: any) => (_ctx.changeGraph(graph))
        }, [
          _createElementVNode("span", null, "(" + _toDisplayString(graph.created_at()) + ") ", 1)
        ], 8, _hoisted_3),
        _createElementVNode("button", {
          class: "ml-4 border-2 p-1 rounded-md",
          onClick: ($event: any) => (_ctx.merge(graph))
        }, "merge", 8, _hoisted_4)
      ]))
    }), 128))
  ]))
}