// important
//   This script is copied from vue to functions.

export type ElementColor = { simple: boolean; value: string; bp: string; name: string };

export const colors = [
  { simple: false, value: "#FEA78C", bp: "", name: "Peach" },
  { simple: false, value: "#FFA3A6", bp: "", name: "Rose" },
  { simple: false, value: "#F583B4", bp: "", name: "Lilac" },
  { simple: false, value: "#CD69A7", bp: "", name: "Magenta" },
  { simple: true, value: "#ED7179", bp: "md", name: "Coral" },
  { simple: true, value: "#FFC2DA", bp: "", name: "Pink" },
  { simple: false, value: "#C58ADE", bp: "", name: "Lavender" },
  { simple: false, value: "#B1BEEA", bp: "", name: "Blue" },
  { simple: false, value: "#90C4E9", bp: "", name: "Sky Blue" },
  { simple: false, value: "#8095CE", bp: "", name: "Light Blue" },
  { simple: false, value: "#FFFFCE", bp: "", name: "Yellow" },
  { simple: true, value: "#FDFD96", bp: "md", name: "Yellow" },
  { simple: false, value: "#EABEBF", bp: "", name: "Pink" },
  { simple: true, value: "#75CCE8", bp: "md", name: "Cyan" },
  { simple: false, value: "#A5DEE5", bp: "", name: "Turquoise" },
  { simple: false, value: "#80BEAF", bp: "", name: "Mint" },
  { simple: true, value: "#B3DDD1", bp: "", name: "Seafoam" },
  { simple: false, value: "#D1DCE2", bp: "md", name: "Light Gray" },
  { simple: false, value: "#F5B994", bp: "", name: "Salmon" },
  { simple: false, value: "#EE9C6A", bp: "", name: "Orange" },
];

export const edgeColors = [
  { simple: true, value: "#808080", bp: "", name: "Grey" },
  { simple: false, bp: "", name: "Pink", value: "#FFC0CB" },
  { simple: true, bp: "", name: "Hot Pink", value: "#FF69B4" },
  { simple: false, bp: "", name: "Yellow", value: "#FFC300" },
  { simple: true, bp: "md", name: "Orange", value: "#FF9F40" },
  { simple: true, bp: "", name: "Green", value: "#4CAF50" },
  { simple: false, bp: "", name: "Blue", value: "#6699ff" },
  { simple: false, bp: "", name: "Purple", value: "#9370DB" },
];
