<template>
  <div>
    <!-- EDIT BUTTON -->
    <div class="flex">
      <template v-if="!history.data.is_public">
        <div @click="toggleEditMode()" class="mr-2 cursor-pointer">
          <span class="hidden lg:inline-block">{{ $t("history.edit") }}</span>
          <i class="material-icons align-middle text-slate-500 transform transition-transform" :class="is_edit_mode ? 'rotate-180' : ''">expand_more</i>
        </div>
      </template>
      <template v-if="!is_edit_mode">
        <!-- SHARE BUTTON -->
        <div v-if="history.data.is_public" class="flex mr-2">
          <a :href="`https://twitter.com/intent/tweet?text=${twitter_text}&url=${public_url}&hashtags=instagraph`" target="_blank" rel="noopener noreferrer">
            <span class="hidden lg:inline-block">{{ $t("history.share") }}</span>
            <i class="material-icons align-middle text-slate-500">publish</i>
          </a>
          <i class="material-icons align-middle text-slate-500" @click="clip()">content_copy</i>
        </div>
        <!-- PUBLISH BUTTON -->
        <div v-else class="flex mr-2 cursor-pointer" @click="setAttempt('publish')">
          <span class="hidden lg:inline-block">{{ $t("history.share") }}</span>
          <i class="material-icons align-middle text-slate-500">publish</i>
        </div>
        <!-- DOWNLOAD BUTTON -->
        <div class="flex mr-2 whitespace-nowrap cursor-pointer" @click="downloadToggle">
          <span class="hidden lg:inline-block">{{ $t("history.download") }}</span>
          <i class="material-icons align-middle text-slate-500">download</i>
        </div>
        <!-- DELETE BUTTON -->
        <div v-if="!history.data.is_public" class="flex ml-4 cursor-pointer" @click="setAttempt('delete')">
          <span class="hidden lg:inline-block">{{ $t("history.delete") }}</span>
          <i class="material-icons align-middle text-slate-500">delete</i>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import GraphDocument from "@/models/graph";

export default defineComponent({
  props: {
    history: {
      type: Object as PropType<GraphDocument>,
      required: true,
    },
    is_edit_mode: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["setAttempt", "toggleEditMode", "downloadToggle"],
  setup(props, context) {
    const public_url = computed(() => {
      return props.history.public_url();
    });

    const clip = () => {
      navigator.clipboard.writeText(public_url.value);
    };
    const setAttempt = (key: string) => {
      context.emit("setAttempt", key);
    };
    const toggleEditMode = () => {
      context.emit("toggleEditMode");
    };
    const downloadToggle = () => {
      context.emit("downloadToggle");
    };
    return {
      public_url,
      twitter_text: props.history.twitter_text(),
      clip,
      setAttempt,
      toggleEditMode,
      downloadToggle,
    };
  },
});
</script>
