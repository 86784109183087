import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout" }
const _hoisted_2 = { class: "bg-warmgray-400 flex h-full flex-col bg-opacity-20" }
const _hoisted_3 = { class: "w-full flex-1 bg-gray-100" }
const _hoisted_4 = { class: "top-0 w-full sm:relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMeun = _resolveComponent("HeaderMeun")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_HeaderMeun),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ])
      ])
    ])
  ]))
}