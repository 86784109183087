<template>
  <span>
    <i class="material-icons align-middle" v-if="is_public">public</i>
    <i class="material-icons align-middle" v-else>locked</i>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    is_public: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
</script>
