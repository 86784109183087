<template>
  <div>
    <div>
      <a
        @click="googleSignin"
        class="inline-flex h-12 items-center justify-center rounded-lg bg-black bg-opacity-5 px-6 shadow-lg hover:bg-blue-600 hover:text-white"
      >
        SignIn with Google account
      </a>
    </div>
    <div v-if="error_message" class="mt-4 py-1 w-1/2 inline-flex items-center justify-center bg-red-400 text-white rounded-lg">
      <div v-if="error_message === 'Unauthorized_email'">
        The Google account you tried to SignIn to has not been invited yet. Please try again with the correct Google account or wait until you are invited.
      </div>
      <div v-else>
        {{ error_message }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { googleSignin } from "@/utils/SocialLogin";

export default defineComponent({
  emits: ["authorized"],
  name: "AccountPage",
  setup(props, context) {
    const error_message = ref<string | null>(null);
    return {
      error_message,
      googleSignin: googleSignin(
        () => {
          context.emit("authorized");
        },
        (error) => {
          console.log(typeof error.message);
          const jsonStartIndex = error.message.indexOf("{");
          const jsonEndIndex = error.message.lastIndexOf("}");

          if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
            const jsonData = error.message.substring(jsonStartIndex, jsonEndIndex + 1);

            try {
              const errorData = JSON.parse(jsonData);
              error_message.value = errorData.error?.message;
              console.error("Error data:", errorData);
            } catch (e) {
              console.error("Failed to parse JSON:", jsonData);
            }
          } else {
            console.error("Error message:", error.message);
          }
        },
      ),
    };
  },
});
</script>
