<template>
  <div class="py-2">
    <div v-for="(graph, k) in related_graphs" :key="k" class="px-4 flex">
      <div class="flex-auto truncate cursor-pointer" @click="changeGraph(graph)">
        <span> {{ graph.data.message }}</span>
      </div>
      <div class="flex-none w-1/5 truncate cursor-pointer" @click="changeGraph(graph)">
        <span>({{ graph.created_at() }}) </span>
      </div>

      <button class="ml-4 border-2 p-1 rounded-md" @click="merge(graph)">merge</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from "vue";

import { db } from "@/utils/firebase";
import { getDocs, collection, collectionGroup, query, where } from "firebase/firestore";

import { GraphDocument } from "@/models";

import { arrayChunk } from "@/utils/utils";
import { merge_user_graph } from "@/utils/functions";

export default defineComponent({
  props: {
    uid: {
      type: String,
      required: true,
    },
    graph_id: {
      type: String,
      required: true,
    },
  },
  emits: ["update_graph_data"],
  setup(props, context) {
    const related_graphs = ref<GraphDocument[]>([]);

    const update_links = async () => {
      const graph_ids: { [key: string]: boolean } = {};
      const labels = await getDocs(collection(db, `user/${props.uid}/graph/${props.graph_id}/labeled_user_graph`));
      const label_array = labels.docs.map((a) => a.data().hashed_label);

      if (label_array.length > 0) {
        await Promise.all(
          arrayChunk(label_array, 10).map(async (ids) => {
            const labeled_user_graphs = await getDocs(
              query(collectionGroup(db, `labeled_user_graph`), where("hashed_label", "in", ids), where("user_id", "==", props.uid)),
            );
            // console.log(ids, labeled_user_graphs);
            labeled_user_graphs.docs.map((b) => {
              const { graph_id } = b.data();
              // console.log(graph_id, props.graph_id);
              if (graph_id !== props.graph_id) {
                graph_ids[graph_id] = true;
              }
            });
          }),
        );
        // console.log(graph_ids);
        if (Object.keys(graph_ids).length > 0) {
          arrayChunk(Object.keys(graph_ids), 10).map(async (ids) => {
            console.log(ids, `user/${props.uid}/graph`);
            const graphs = await getDocs(query(collection(db, `user/${props.uid}/graph`), where("graph_id", "in", ids), where("is_deleted", "==", false)));
            related_graphs.value = graphs.docs.map((b) => new GraphDocument(b)); // TODO more than 10;
          });
        }
      }
    };

    watch(
      () => props.graph_id,
      (v) => {
        related_graphs.value = [];
        if (v) {
          update_links();
        }
      },
      { immediate: true },
    );

    const changeGraph = (graph: GraphDocument) => {
      context.emit("update_graph_data", graph);
      // console.log(graph);
    };
    const merge = async (graph: GraphDocument) => {
      const merge_res = await merge_user_graph({ from_graph_id: props.graph_id, to_graph_id: graph.id });
      context.emit("update_graph_data", merge_res.data);
    };
    return {
      related_graphs,
      changeGraph,
      merge,
    };
  },
});
</script>
