import { Position } from "cytoscape";

export const load_positions = (graph_id: string) => {
  const key = "graph_positions_" + graph_id;
  const str = localStorage.getItem(key);
  if (str) {
    const positions = JSON.parse(str);
    return positions;
  }
  return null;
};

export const set_positions = (graph_id: string, position: { [key: string]: Position }) => {
  const key = "graph_positions_" + graph_id;
  localStorage.setItem(key, JSON.stringify(position));
};
