<template>
  <div>
    <div class="px-6 pt-2 gap-6 h-screen">
      <div class="w-full h-screen bg-white rounded-md pt-4">
        <GoogleLogin @authorized="authorized" />
        <div class="mt-8">SignIn with the google account you were invited to via invitation.</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { noLoginPage, useLocalizedRoute } from "@/utils/utils";
import GoogleLogin from "@/components/GoogleLogin.vue";

export default defineComponent({
  name: "AccountPage",
  components: {
    GoogleLogin,
  },
  setup() {
    noLoginPage("/");
    const routePush = useLocalizedRoute();

    const authorized = () => {
      routePush("/");
    };
    return { authorized };
  },
});
</script>
