import languages from "./languages";

const lang = {
  message: {
    hello: "こんにちは、世界",
  },
  languages,
  menu: {
    top: "Home",
    signin: "SignIn",
    signout: "SignOut",
    about: "Abount",
    list: "Public graphs",
  },
  history: {
    publish: "一般公開",
    share: "シェア",
    public_url: "公開URL",
    download: "ダウンロード",
    delete: "消去",
    edit: "編集",
    may_publish: "一度シェアすると、誰でもこのノリッジ・グラフにアクセスし、二次利用が可能になります(CC0)。よろしいですか?",
    may_delete: "一度消去したものは、元に戻せません。よろしいですか?",
    yes_publish: "同意する",
    yes_delete: "消去する",
    cancel: "キャンセル",
    add_node: "新しいノードの追加",
    add_edge: "新しいエッジの追加",
    delete_node: "削除",
    may_delete_node: "ノードを削除すると、それに繋がる全てのエッジも削除されます。よろしいですか？",
    may_delete_edge: "このエッジを削除してよろしいですか？",
    color: "色",
    type: "タイプ",
    direction: "方向性",
    about_cc: "CC0とは",
  },
  editor: {
    url: "URL",
    title: "タイトル",
    nodes: "ノード",
    edges: "エッジ",
    sort: "並べ替え",
    details: "詳細",
    details_of: "{node}についての詳細",
  },
  home: {
    question: "Question",
    advanced: "Advanced...",
    no_graph_error: "グラフの生成に失敗しました。質問の文言を変更して、再度試みてください。",
    openai_down_error: "OpenAIのサーバエラーです。エラーが連続する場合はOpenAIの調子が悪い可能性があります",
    firebase_exception: "Firebaseエラーです。再度試みてください。",
    user_input_question: "何でも質問してください。例１：太陽系、例２：原子力発電所",
    placeholder_summary: "Webサイト、もしくはwebコンテンツの要約",
    url_sample: "https://example.com/sample123.html (オプション)",
    merge_graph: "既存のグラフに結果を追加する",
  },
};

export default lang;
