<template>
  <div v-if="graph_data && is_edit_mode" class="text-left bg-blue-100 px-2 rounded-lg">
    <!-- UNDO/REDO BUTTON -->
    <div class="flex pt-1">
      <button class="pr-2 cursor-pointer disabled:opacity-30" @click="undo" :disabled="!isUndoable">
        <i class="material-icons align-middle text-slate-500">undo</i>
      </button>
      <button class="cursor-pointer disabled:opacity-30" @click="redo" :disabled="!isRedoable">
        <i class="material-icons align-middle text-slate-500">redo</i>
      </button>
    </div>
    <!-- Graph Title -->
    <div class="flex pt-1">
      <div class="basis-20">
        <span class="">{{ $t("editor.title") }}</span>
      </div>
      <input
        type="text"
        :value="graph_data.title"
        @input="(event) => onTextInputOnGraph(event, 'title')"
        @keyup.enter="emitGraphUpdated"
        @blur="emitGraphUpdated"
        class="ml-2 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full px-2"
        required
      />
    </div>
    <!-- Graph URL -->
    <div class="flex py-1">
      <div class="basis-20">
        <span class="">{{ $t("editor.url") }}</span>
      </div>
      <input
        type="text"
        :value="graph_data.related_url"
        @input="(event) => onTextInputOnGraph(event, 'related_url')"
        @keyup.enter="emitGraphUpdated"
        @blur="emitGraphUpdated"
        class="ml-2 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full px-2"
        required
      />
    </div>
    <!-- Nodes -->
    <div class="flex pt-1">
      <div class="basis-20">
        <span class="">{{ $t("editor.nodes") }}</span>
      </div>
      <div class="cursor-pointer" @click="sortNodes">
        <span class="hidden lg:inline-block">{{ $t("editor.sort") }}</span>
        <i class="flex-none material-icons align-middle text-slate-500 w-6">sort</i>
      </div>
    </div>

    <div v-for="node in graph_data.graph_data.elements.nodes" :key="node.data.id" ref="node_panel" :data-index="node.data.id">
      <div>
        <div class="flex">
          <!-- NODE -->
          <div class="grow flex pl-2">
            <i class="material-icons align-middle text-slate-500 w-6" :style="`color:${node.data.color}`">square</i>
            <input
              type="text"
              :value="node.data.label"
              @input="(event) => onTextInputOnNode(event, node)"
              @keyup.enter="emitGraphUpdated"
              @blur="emitGraphUpdated"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full px-2"
              required
            />
          </div>
          <div class="flex-none w-6 cursor-pointer" @click="toggleNodePanel(node.data.id!)">
            <i class="material-icons align-middle text-slate-500 transform transition-transform" :class="node.data.id === nodeWithPanel ? 'rotate-180' : ''"
              >expand_more</i
            >
          </div>
        </div>
      </div>
      <!-- Node Panel -->
      <div v-if="node.data.id === nodeWithPanel" class="">
        <div class="px-2 bg-blue-50 py-1 my-1 rounded-lg">
          <!-- BUTTON BAR -->
          <div class="flex">
            <!-- DETAILS BUTTON -->
            <div class="flex cursor-pointer select-none" @click="details">
              <span>{{ $t("editor.details") }}</span>
              <i class="material-icons align-middle text-slate-500">help</i>
            </div>
            <div class="flex-grow"></div>
            <!-- DELETE NODE BUTTON -->
            <div class="flex cursor-pointer select-none" @click="setAttempt('delete_node')">
              <span>{{ $t("history.delete_node") }}</span>
              <i class="material-icons align-middle text-slate-500">delete</i>
            </div>
          </div>
          <!-- CONFIRM PANEL: Delete Node -->
          <ConfirmPanel
            v-if="attempt === 'delete_node'"
            message_id="history.may_delete_node"
            yes_id="history.yes_delete"
            no_id="history.cancel"
            @yes="confirm()"
            @no="cancel()"
          />
          <!-- NODE Type -->
          <div class="flex my-1">
            <div class="basis-20">
              <span class="">{{ $t("history.type") }}</span>
            </div>
            <input
              type="text"
              :value="node.data.type"
              @input="(event) => onTextInputOnNode(event, node, 'type')"
              @keyup.enter="emitGraphUpdated"
              @blur="emitGraphUpdated"
              class="ml-2 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full px-2"
              required
            />
          </div>
          <!-- NODE color -->
          <div class="flex my-1">
            <div class="pr-6 cursor-pointer" @click="colorPaletteToggle">
              <span>{{ $t("history.color") }}</span>
            </div>
            <div>
              <div class="flex">
                <div>
                  <i
                    class="material-icons align-middle text-slate-500 cursor-pointer transform transition-transform"
                    :class="isPaletteOpen ? 'rotate-180' : ''"
                    @click="colorPaletteToggle"
                    >expand_less</i
                  >
                </div>
                <div class="w-full">
                  <ColorPalette
                    @setColor="(e) => onTextInputOnNode({ target: { value: e } }, node, 'color', true)"
                    :colors="colors"
                    :isSimple="!isPaletteOpen"
                    :selected_color="node.data.color"
                  />
                </div>
              </div>
              <input
                v-if="isPaletteOpen"
                type="text"
                :value="node.data.color"
                @input="(event) => onTextInputOnNode(event, node, 'color')"
                @keyup.enter="emitGraphUpdated"
                @blur="emitGraphUpdated"
                class="mt-2 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full px-2"
                required
              />
            </div>
          </div>
          <div class="flex"></div>
          <!-- Edges started from this Node -->
          <!-- Nodes -->
          <div class="py-1">
            <span class="">{{ $t("editor.edges") }}</span>
          </div>
          <div v-for="edge in filteredEdges" :key="edge.data.id" class="mb-4">
            <!-- Edge -->
            <!-- Edge Title -->
            <div class="flex">
              <div class="flex-none">
                <i class="flex-none material-icons align-middle text-slate-500 w-6" :style="`color:${edge.data.color}`">east</i>
              </div>
              <div class="flex-1 mx-1">
                <input
                  type="text"
                  :value="edge.data.label"
                  @input="(event) => onTextInputOnEdge(event, edge)"
                  @keyup.enter="emitGraphUpdated"
                  @blur="emitGraphUpdated"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full px-2"
                  required
                />
              </div>
              <!-- End Node -->
              <div class="flex-1 truncate ml-2">
                {{ nodeLabel(edge.data.target) }}
              </div>
              <!-- DELETE Edge BUTTON -->
              <div class="flex-none cursor-pointer" @click="setAttempt('delete_edge', edge.data.id)">
                <i class="material-icons align-middle text-slate-500">delete</i>
              </div>
            </div>
            <!-- Edge color & direction -->
            <div class="flex my-1">
              <!-- color -->
              <!--  edge.data.id -->
              <div class="pl-6">
                <div class="flex">
                  <div>
                    <i
                      class="material-icons align-middle text-slate-500 cursor-pointer transform transition-transform"
                      :class="selected_edge_palette === edge.data.id ? 'rotate-180' : ''"
                      @click="update_selected_edge_palette(edge.data.id)"
                      >expand_less</i
                    >
                  </div>
                  <div>
                    <ColorPalette
                      @setColor="(e) => onTextInputOnEdge({ target: { value: e } }, edge, 'color', true)"
                      :colors="edgeColors"
                      :isSimple="selected_edge_palette !== edge.data.id"
                      :selected_color="edge.data.color"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  :value="edge.data.color"
                  @input="(event) => onTextInputOnEdge(event, edge, 'color')"
                  @keyup.enter="emitGraphUpdated"
                  @blur="emitGraphUpdated"
                  class="ml-2 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full px-2"
                  required
                  v-show="selected_edge_palette === edge.data.id"
                />
              </div>
              <!-- End of color -->
              <div class="basis-20 ml-auto whitespace-nowrap">
                <input
                  type="checkbox"
                  value="foo"
                  :checked="edge.data.directed"
                  @change="(event) => onCheckboxOnEdge(event, edge, 'directed')"
                  class="bg-gray-50 mr-1"
                  required
                  :id="'directed_' + edge.data.id"
                />
                <label :for="'directed_' + edge.data.id">{{ $t("history.direction") }}</label>
              </div>
            </div>
            <!-- CONFIRM PANEL: Delete Edge -->
            <ConfirmPanel
              v-if="attempt === 'delete_edge' && edge.data.id === edgeToDelete"
              :message_id="`history.may_delete_edge`"
              :yes_id="`history.yes_delete`"
              no_id="history.cancel"
              @yes="confirm()"
              @no="cancel()"
            />
          </div>
          <!-- New Edge button -->
          <div @click="showNodesForNewEdge" class="cursor-pointer select-none">
            <i class="flex-none material-icons align-middle text-slate-500 w-6 mr-1">east</i>
            <span>{{ $t("history.add_edge") }}</span>
          </div>
          <!-- Nodes for a new edge -->
          <div v-if="isNodesVisibleForNewEdge">
            <div v-for="node2 in nodesForNewEdge" :key="node2.data.id">
              <div @click="addNewEdge(node2.data.id!)" class="cursor-pointer truncate">
                <i class="flex-none material-icons align-middle text-slate-500 w-6 ml-6">add_box</i>
                {{ node2.data.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="addNewNode" class="cursor-pointer select-none pl-2">
      <i class="material-icons align-middle text-slate-500 w-6 mr-1" style="color: #aaaaaa">add_box</i>
      <span>{{ $t("history.add_node") }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { useToggle } from "@/utils/utils";
import { defineComponent, ref, watch, computed, onBeforeUnmount, PropType, nextTick } from "vue";
import { db } from "@/utils/firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { GraphDocumentData, SelectData } from "@/models/graph";
import { NodeDefinition, EdgeDefinition } from "cytoscape";
import ConfirmPanel from "@/components/ConfirmPanel.vue";
import ColorPalette from "@/components/ColorPalette.vue";
import { v4 as uuidv4 } from "uuid";
import { colors, edgeColors } from "@/utils/const";
import { AttemptTypes } from "@/utils/types";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  emits: ["graph_updated", "graph_fetched", "present_question"],
  props: {
    graph_id: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
    is_edit_mode: {
      type: Boolean,
      required: true,
    },
    select_data: {
      type: Object as PropType<SelectData>,
    },
    fetch_graph_from_store: {
      type: Boolean,
    },
  },
  components: {
    ConfirmPanel,
    ColorPalette,
  },
  setup(props, context) {
    const store = useStore();
    const graph_data = ref<GraphDocumentData | null>(null);
    const change_history = ref<GraphDocumentData[]>([]);
    const history_index = ref<number>(0);
    const nodeWithPanel = ref<string>("");
    const attempt = ref<AttemptTypes>("");
    const edgeToDelete = ref<string>("");
    const isNodesVisibleForNewEdge = ref<boolean>(false);
    const isUpdated = ref<boolean>(false); // updated, but not saved yet
    const isDirty = ref<boolean>(false); // updated, but not reflected yet
    const node_panel = ref<HTMLElement[] | null>(null);
    const { t } = useI18n();

    const { toggle: colorPaletteToggle, value: isPaletteOpen } = useToggle(false);
    const selected_edge_palette = ref("");
    const update_selected_edge_palette = (id: string) => {
      selected_edge_palette.value = id === selected_edge_palette.value ? "" : id;
    };

    const fetch_graph_data = async () => {
      const docRef = doc(db, `user/${props.uid}/graph/${props.graph_id}`);
      const docSnap = await getDoc(docRef);
      graph_data.value = docSnap.data() as GraphDocumentData;
      change_history.value = [graph_data.value];
      history_index.value = 0;
    };

    fetch_graph_data();
    watch(
      () => props.graph_id,
      () => {
        fetch_graph_data();
      },
    );
    watch(
      // Detect the updateg graph by the grand parent (Home)
      () => props.fetch_graph_from_store,
      (newValue) => {
        if (newValue) {
          const graph = store.state.updatedGraph;
          updateElements(graph.elements.nodes, graph.elements.edges, true);
          context.emit("graph_fetched");
        }
      },
    );

    const onTextInputOnGraph = (event: Event, key: string) => {
      if (graph_data.value) {
        const target = event.target as HTMLInputElement;
        graph_data.value = { ...graph_data.value, [key]: target.value };
        isUpdated.value = true;
        isDirty.value = true;
      }
    };

    const onTextInputOnNode = (event: Event, nodeIn: NodeDefinition, key: string = "label", updageGraph: boolean = false) => {
      if (graph_data.value) {
        const target = event.target as HTMLInputElement;
        const nodes = graph_data.value.graph_data.elements.nodes.map((node) => {
          if (node.data.id == nodeIn.data.id) {
            return { ...node, data: { ...node.data, [key]: target.value } };
          }
          return node;
        });
        updateElements(nodes, null, updageGraph);
      }
    };

    const onTextInputOnEdge = (event: Event, edgeIn: EdgeDefinition, key: string = "label", updageGraph: boolean = false) => {
      if (graph_data.value) {
        const target = event.target as HTMLInputElement;
        const edges = graph_data.value.graph_data.elements.edges.map((edge) => {
          if (edge.data.id == edgeIn.data.id) {
            return { ...edge, data: { ...edge.data, [key]: target.value } };
          }
          return edge;
        });
        updateElements(null, edges, updageGraph);
      }
    };

    const onCheckboxOnEdge = (event: Event, edgeIn: EdgeDefinition, key: string = "label") => {
      if (graph_data.value) {
        const target = event.target as HTMLInputElement;
        const edges = graph_data.value.graph_data.elements.edges.map((edge) => {
          if (edge.data.id == edgeIn.data.id) {
            return { ...edge, data: { ...edge.data, [key]: target.checked } };
          }
          return edge;
        });
        updateElements(null, edges);
      }
    };

    onBeforeUnmount(() => {
      console.log("GraphEditor onBeforeUnmount");
      saveGraphIfUpdated();
    });
    window.onbeforeunload = () => {
      if (isUpdated.value) {
        // NOTE: Notice that any async operation (such as saving to the database)
        // withing these calls won't be executed if the user choose to continue.
        saveGraphIfUpdated();
        emitGraphUpdated();
        return true; // telling the browser to ask the user.
      }
    };

    const scrollToNodePanel = async () => {
      await nextTick();
      if (node_panel.value) {
        const matched = node_panel.value.find((a) => {
          return a.dataset["index"] === nodeWithPanel.value;
        });
        if (matched) {
          matched.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    watch(
      () => props.select_data,
      (v) => {
        // console.log(v?.group, props.is_edit_mode);
        if (v && graph_data.value) {
          if (v.group === "nodes") {
            nodeWithPanel.value = v.id;
            scrollToNodePanel();
          } else if (v.group == "edges") {
            graph_data.value.graph_data.elements.edges.forEach((edge) => {
              if (edge.data.id === v.id) {
                nodeWithPanel.value = edge.data.source;
                scrollToNodePanel();
              }
            });
          }
        }
      },
    );
    const mergeNodesWithSameLabel = () => {
      if (graph_data.value) {
        const label2id: Record<string, string> = {};
        const id2id: Record<string, string> = {}; // id mapping
        const nodes = graph_data.value.graph_data.elements.nodes.filter((node) => {
          const id = label2id[node.data.label];
          if (id) {
            id2id[node.data.id!] = id;
            return false;
          }
          id2id[node.data.id!] = node.data.id!;
          label2id[node.data.label] = node.data.id!;
          return true;
        });

        if (nodes.length < graph_data.value.graph_data.elements.nodes.length) {
          console.log("*** merging nodes");
          const edges = graph_data.value.graph_data.elements.edges.map((edge) => {
            return { ...edge, data: { ...edge.data, source: id2id[edge.data.source], target: id2id[edge.data.target] } };
          });

          graph_data.value = { ...graph_data.value, graph_data: { ...graph_data.value.graph_data, elements: { nodes, edges } } };
        }
      }
    };

    const saveGraphIfUpdated = async () => {
      console.log("saveGraphIfUpdated was called", isUpdated.value);
      if (isUpdated.value && graph_data.value) {
        console.log("*** saving...");
        mergeNodesWithSameLabel();

        const path = `/user/${props.uid}/graph/${graph_data.value.graph_id}`;
        const data = graph_data.value.graph_data;
        try {
          await updateDoc(doc(db, path), {
            graph_data: data,
            title: graph_data.value.title || "",
            related_url: graph_data.value.related_url || "",
          });
        } catch (e) {
          console.error("ERROR", e);
          //const jsonString = JSON.stringify(data, null, 2);
          //console.log(jsonString);
        }
      }
      isUpdated.value = false;
    };
    const emitGraphUpdated = async () => {
      if (graph_data.value && isDirty.value) {
        mergeNodesWithSameLabel();

        console.log("emitGraphUPdated", history_index.value, change_history.value.length);
        const history = change_history.value.filter((v, index) => index < history_index.value + 1);
        history.push(graph_data.value);
        change_history.value = history;
        history_index.value = history_index.value + 1;

        if (graph_data.value.graph_id === props.graph_id) {
          context.emit("graph_updated", graph_data.value);
        }
        isDirty.value = false;
      }
    };

    const toggleNodePanel = (id: string) => {
      nodeWithPanel.value = nodeWithPanel.value === id ? "" : id;
    };

    const details = () => {
      if (graph_data.value) {
        const node = graph_data.value.graph_data.elements.nodes.find((node) => {
          return node.data.id === nodeWithPanel.value;
        });
        if (node) {
          const question = t("editor.details_of", { node: node.data.label });
          context.emit("present_question", question);
        }
      }
    };

    watch(
      () => props.is_edit_mode,
      (newValue) => {
        if (!newValue) {
          saveGraphIfUpdated();
          emitGraphUpdated();
          nodeWithPanel.value = "";
        }
      },
    );

    watch(nodeWithPanel, () => {
      isNodesVisibleForNewEdge.value = false;
    });

    const setAttempt = (key: AttemptTypes, id: string = "") => {
      console.log("*** setAttempt", key, id);
      attempt.value = key;
      if (key === "delete_edge") {
        edgeToDelete.value = id;
      }
    };
    const cancel = () => {
      attempt.value = "";
    };
    const updateElements = (nodes: NodeDefinition[] | null, edges: EdgeDefinition[] | null, withEmit: boolean = true) => {
      if (graph_data.value) {
        // NOTE: We make deep enough copy, which is enough for undo/redo.
        const elements = { ...graph_data.value.graph_data.elements };
        if (nodes) {
          elements.nodes = nodes;
        }
        if (edges) {
          elements.edges = edges;
        }
        graph_data.value = { ...graph_data.value, graph_data: { ...graph_data.value.graph_data, elements } };

        isUpdated.value = true;
        isDirty.value = true;
        if (withEmit) {
          emitGraphUpdated();
        }
      }
    };
    const confirm = async () => {
      if (graph_data.value) {
        if (attempt.value === "delete_node") {
          console.log("deleing", nodeWithPanel.value);
          const edges = graph_data.value.graph_data.elements.edges.filter((edge) => {
            return edge.data.source !== nodeWithPanel.value && edge.data.target !== nodeWithPanel.value;
          });
          const nodes = graph_data.value.graph_data.elements.nodes.filter((node) => {
            return node.data.id !== nodeWithPanel.value;
          });
          updateElements(nodes, edges);
        }
        if (attempt.value === "delete_edge") {
          console.log("deleing", edgeToDelete.value);
          const edges = graph_data.value.graph_data.elements.edges.filter((edge) => {
            return edge.data.id !== edgeToDelete.value;
          });
          updateElements(null, edges);
        }
      }
      attempt.value = "";
    };
    const filteredEdges = computed(() => {
      if (nodeWithPanel.value && graph_data.value) {
        const edges = graph_data.value.graph_data.elements.edges.filter((edge) => {
          return edge.data.source === nodeWithPanel.value;
        });
        return edges;
      }
      return [];
    });
    const nodeLabel = (node_id: string) => {
      const nodes = graph_data.value!.graph_data.elements.nodes.filter((node) => {
        return node.data.id === node_id;
      });
      return nodes[0].data.label;
    };
    const addNewNode = () => {
      if (graph_data.value) {
        const id = uuidv4();
        const nodes = graph_data.value.graph_data.elements.nodes.map((node) => node);
        nodes.push({
          data: {
            id,
            label: `node ${nodes.length}`,
            type: nodes.length > 0 ? nodes[0].data.type : "Object",
            color: "#aaaaaa",
          },
        });
        updateElements(nodes, null);
      }
    };
    const showNodesForNewEdge = () => {
      isNodesVisibleForNewEdge.value = !isNodesVisibleForNewEdge.value;
      console.log("*** showNodesForNewEdge", isNodesVisibleForNewEdge.value);
    };
    const addNewEdge = (target_id: string) => {
      if (graph_data.value) {
        const id = uuidv4();
        const edges = graph_data.value.graph_data.elements.edges.map((edge) => edge);
        edges.push({
          data: {
            id,
            label: `edge ${edges.length}`,
            source: nodeWithPanel.value,
            target: target_id,
            color: "#808080",
            directed: true,
          },
        });
        updateElements(null, edges);
      }
    };
    const nodesForNewEdge = computed(() => {
      return graph_data.value!.graph_data.elements.nodes.filter((node) => {
        return node.data.id !== nodeWithPanel.value;
      });
    });
    const sortNodes = () => {
      if (graph_data.value) {
        const nodes = graph_data.value?.graph_data.elements.nodes.sort((a, b) => {
          return a.data.label > b.data.label ? 1 : -1;
        });
        updateElements(nodes, null);
      }
    };
    const undo = () => {
      console.log("undoing", history_index.value, change_history.value.length);
      if (history_index.value > 0) {
        history_index.value = history_index.value - 1;
        graph_data.value = change_history.value[history_index.value];
        context.emit("graph_updated", graph_data.value);
        isUpdated.value = true;
      }
    };
    const redo = () => {
      console.log("redo");
      if (history_index.value < change_history.value.length - 1) {
        history_index.value = history_index.value + 1;
        graph_data.value = change_history.value[history_index.value];
        context.emit("graph_updated", graph_data.value);
        isUpdated.value = true;
      }
    };
    const isUndoable = computed(() => {
      return history_index.value > 0;
    });
    const isRedoable = computed(() => {
      return history_index.value < change_history.value.length - 1;
    });

    return {
      graph_data,
      onTextInputOnNode,
      onTextInputOnEdge,
      emitGraphUpdated,
      toggleNodePanel,
      nodeWithPanel,
      attempt,
      setAttempt,
      confirm,
      cancel,
      filteredEdges,
      nodeLabel,
      edgeToDelete,
      addNewNode,
      showNodesForNewEdge,
      addNewEdge,
      isNodesVisibleForNewEdge,
      nodesForNewEdge,
      onCheckboxOnEdge,
      node_panel,
      onTextInputOnGraph,
      saveGraphIfUpdated,
      sortNodes,

      colorPaletteToggle,
      isPaletteOpen,

      selected_edge_palette,
      update_selected_edge_palette,
      colors,
      edgeColors,
      undo,
      redo,
      isUndoable,
      isRedoable,
      details,
    };
  },
});
</script>
