<template>
  <div class="flex flex-wrap w-7/8 mt-8 justify-center">
    <div v-for="(graph, k) in graphs" :key="k" class="flex">
      <router-link :to="graph.location_path()">
        <img :src="graph.data.thumbnail_url" class="w-40" v-if="graph.data.thumbnail_url" />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import { db } from "@/utils/firebase";
import { getDocs, collectionGroup, query, orderBy, where, limit } from "firebase/firestore";
import GraphDocument from "@/models/graph";

export default defineComponent({
  props: {
    user_id: String,
  },
  setup(props) {
    const graphs = ref<GraphDocument[]>([]);
    const base_query = query(
      collectionGroup(db, "graph"),
      where("is_public", "==", true),
      where("is_deleted", "==", false),
      orderBy("created_at", "desc"),
      limit(50),
    );
    const load_graph = async () => {
      const graphCollection = await getDocs(base_query);
      graphs.value = graphCollection.docs.map((a) => new GraphDocument(a));
    };
    const user_load_graph = async () => {
      const graphCollection = await getDocs(query(base_query, where("uid", "==", props.user_id)));
      graphs.value = graphCollection.docs.map((a) => new GraphDocument(a));
      console.log("AA", graphs.value);
    };
    if (props.user_id) {
      user_load_graph();
    } else {
      load_graph();
    }
    return {
      graphs,
    };
  },
});
</script>
