<template>
  <div>
    <div class="relative flex items-center">
      <div @click="toggleMenu()" class="inline-flex h-12 w-14 pt-1 flex-shrink-0 cursor-pointer items-center justify-center absolute z-40">
        <span class="material-icons text-warmgray-900 text-opacity-60 block">menu</span>
      </div>
      <div class="w-full items-center h-10 font-montserrat text-2xl z-30 mt-2 pt-1">
        <router-link to="/">InstaGraph</router-link>
      </div>
      <div v-show="menu" class="fixed top-0 left-0 z-50 flex h-screen w-screen">
        <div class="bg-warmgray-100 flex w-64 flex-col bg-white shadow">
          <MenuList @close-menu="toggleMenu()" />
        </div>
        <div @click="toggleMenu()" class="flex-1 cursor-pointer bg-black bg-opacity-40"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import MenuList from "@/components/menu/list.vue";

export default defineComponent({
  components: {
    MenuList,
  },
  async setup() {
    const menu = ref(false);
    const toggleMenu = () => {
      menu.value = !menu.value;
    };
    return {
      menu,
      toggleMenu,
    };
  },
});
</script>
