import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grow text-left truncate px-4 text-ellipsis white-space-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_public_or_private = _resolveComponent("public_or_private")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex cursor-pointer", _ctx.is_selected ? 'bg-blue-500' : '']),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectGraph(_ctx.history)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.is_selected ? 'text-white' : '')
      }, _toDisplayString(_ctx.history.data.message), 3)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["text-opacity-40 flex-none w-8 pr-2", _ctx.is_selected ? 'text-white' : 'text-slate-500'])
    }, [
      _createVNode(_component_public_or_private, {
        is_public: _ctx.history.data.is_public
      }, null, 8, ["is_public"])
    ], 2)
  ], 2))
}