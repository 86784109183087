<template>
  <div class="py-2 overflow-x-hidden">
    <div v-for="history in histories" :key="history.graph_id">
      <div v-if="history.data.graph_id == graph_id">
        <div ref="selected_element">
          <HistoryElement @select-graph="selectGraph" :history="history" :is_selected="true" />
        </div>
      </div>
      <div v-else>
        <HistoryElement @select-graph="selectGraph" :history="history" :is_selected="false" />
      </div>
      <div v-if="history.data.graph_id == graph_id" class="bg-blue-50 py-2">
        <HistoryButtons
          @downloadToggle="downloadToggle"
          @toggle-edit-mode="toggleEditMode"
          @set-attempt="setAttempt"
          :is_edit_mode="isEditMode"
          :history="history"
          class="px-2"
        />
        <!-- PUBLISHING ANIMATIOM -->
        <div v-if="!history.data.is_public && publishing_id == history.data.graph_id" class="w-full h-3 bg-white rounded-md mt-4 loading block px-2">
          &nbsp;
        </div>
        <!-- EDIT PANEL -->
        <GraphEditor
          :graph_id="graph_id"
          :uid="uid"
          :is_edit_mode="isEditMode"
          :select_data="select_data_down"
          :fetch_graph_from_store="fetch_graph_from_store"
          @graph_updated="graphUpdated"
          @graph_fetched="graph_fetched"
          @present_question="present_question"
        ></GraphEditor>
        <!-- Publish Title -->
        <!-- CONFIRM PANEL: Publish or Delete -->
        <ConfirmPanel
          v-if="attempt"
          :message_id="`history.may_${attempt}`"
          :yes_id="`history.yes_${attempt}`"
          :is_cc="attempt === `publish`"
          no_id="history.cancel"
          @yes="confirm()"
          @no="cancel()"
        >
          <div v-if="attempt === 'publish'" class="my-4">
            Title
            <input type="text" v-model="title" class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full px-2" />
          </div>
        </ConfirmPanel>
        <!-- Download -->
        <div class="px-2 py-1 mt-2 mb-2 bg-white rounded-lg" v-if="isDownloadOpen">
          <a :href="download_url" class="underline" download="InstaGraph.json"> JSON data </a> /
          <a :href="download_png" class="underline" download="InstaGraph.png"> PNG image </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, PropType, nextTick } from "vue";
import { useStore } from "vuex";

import GraphDocument, { GraphDocumentData, SelectData } from "@/models/graph";

import { useUser, useDetacher, getBrowserLocales, useToggle } from "@/utils/utils";
import { make_public } from "@/utils/functions";
import { upload_base64_png, base64ToUint8array } from "@/utils/firebase_utils";
import { load_positions } from "@/utils/position";

import { db } from "@/utils/firebase";
import { onSnapshot, collection, query, orderBy, where, doc, updateDoc, limit } from "firebase/firestore";

import HistoryElement from "@/components/history/HistoryElement.vue";
import HistoryButtons from "@/components/history/HistoryButtons.vue";
import ConfirmPanel from "@/components/ConfirmPanel.vue";
import GraphEditor from "@/components/GraphEditor.vue";

export default defineComponent({
  emits: ["update_graph_data", "present_question"],
  props: {
    graph_id: {
      type: String,
      required: true,
    },
    select_data: {
      type: Object as PropType<SelectData>,
    },
  },
  components: {
    HistoryElement,
    HistoryButtons,
    ConfirmPanel,
    GraphEditor,
  },
  setup(props, context) {
    const store = useStore();

    const histories = ref<GraphDocument[]>([]);
    const user = useUser();
    const attempt = ref<string>("");
    const publishing_id = ref<string>("");
    const isEditMode = ref<boolean>(false);
    const title = ref<string>("");
    const selected_element = ref<HTMLElement[] | null>(null);
    const fetch_graph_from_store = ref<boolean>(false);
    const select_data_down = ref<SelectData | undefined>(undefined);

    const { toggle: downloadToggle, value: isDownloadOpen, toggleOff: downloadClose } = useToggle(false);
    const { detachers } = useDetacher("list");
    watch(isDownloadOpen, (v) => {
      if (v) {
        attempt.value = "";
      }
    });
    watch(attempt, (v) => {
      if (v !== "") {
        downloadClose();
      }
    });
    watch(
      // Detect the updateg graph by the parent (Home)
      () => store.state.updatedGraph,
      (newValue) => {
        if (newValue) {
          // The graph was updated. Turn on the edit mode and notifiy it to the GraphEditor.
          isEditMode.value = true;
          fetch_graph_from_store.value = true;
        }
      },
    );
    const graph_fetched = () => {
      // The GraphEditor has completed fetching the updated graph.
      fetch_graph_from_store.value = false;
      store.commit("setUpdatedGraph", null);
    };

    const { uid } = user.value;

    console.log(`user/${uid}/graph`);

    const graph_query = query(collection(db, `user/${uid}/graph`), where("is_deleted", "==", false), orderBy("created_at", "desc"), limit(100));
    const detacher = onSnapshot(graph_query, (res) => {
      console.log("list update");
      histories.value = res.docs.map((a) => new GraphDocument(a));
    });
    detachers.push(detacher);

    const selected_item = computed(() => {
      if (props.graph_id) {
        for (const h of histories.value) {
          if (h.graph_id == props.graph_id) {
            return h;
          }
        }
      }
      return {} as GraphDocument; // Type inference is not good so it's a charm.
    });

    watch(
      () => props.graph_id,
      () => {
        attempt.value = "";
        isEditMode.value = false;
      },
    );
    watch(
      () => props.select_data,
      (v) => {
        // console.log(v?.group, props.is_edit_mode);
        const graph_data = selected_item.value.data;
        if (v && graph_data) {
          if (!graph_data.is_public) {
            isEditMode.value = true;
            select_data_down.value = props.select_data;
          }
        }
      },
    );

    const selectGraph = async (h: GraphDocument) => {
      context.emit("update_graph_data", h.data);
      if (selected_element.value && selected_element.value.length > 0) {
        await nextTick();
        selected_element.value[0].scrollIntoView({ behavior: "smooth" });
      }
    };
    const setAttempt = (key: string) => {
      if (attempt.value === key) {
        attempt.value = "";
        return;
      }
      attempt.value = key;
      publishing_id.value = "";
      isEditMode.value = false;
      // NOTE: Unsaved change on the title will not be reflected here (by design)
      title.value = selected_item.value.data?.title || "";
    };
    const cancel = () => {
      attempt.value = "";
    };
    const confirm = async () => {
      const h = selected_item.value.data;
      if (h.graph_id) {
        if (attempt.value == "publish") {
          publishing_id.value = h.graph_id;
          const thumbnail_url = await upload_base64_png(store.state.cy.png({ bg: "white" }).split(",")[1], uid, h.graph_id);
          console.log(`publishing:${h.graph_id}`);
          const positions = load_positions(h.graph_id);
          const { locale, short_locale } = getBrowserLocales();
          await make_public({ graph_id: h.graph_id, thumbnail_url, positions, title: title.value, locale, short_locale });

          selected_item.value.data.title = title.value;
          const public_url = selected_item.value.public_url();
          const twitter_text = selected_item.value.twitter_text();
          const url = `https://twitter.com/intent/tweet?text=${twitter_text}&url=${public_url}&hashtags=instagraph`;
          window.open(url, "_blank");
        }
        if (attempt.value == "delete") {
          const path = `/user/${uid}/graph/${h.graph_id}`;
          await updateDoc(doc(db, path), { is_deleted: true });
          context.emit("update_graph_data", null);
        }
      }
      attempt.value = "";
    };
    const toggleEditMode = () => {
      isEditMode.value = !isEditMode.value;
      attempt.value = "";
    };
    const graphUpdated = (graph_data: GraphDocumentData) => {
      context.emit("update_graph_data", graph_data);
    };

    const download_url = computed(() => {
      if (selected_item.value) {
        return window.URL.createObjectURL(selected_item.value.json_binary());
      }
      return "";
    });
    const download_png = computed(() => {
      if (selected_item.value) {
        const pngData = base64ToUint8array(store.state.cy.png({ bg: "white" }).split(",")[1]);
        const blob = new Blob([pngData], {
          type: "image/png",
        });
        return window.URL.createObjectURL(blob);
      }
      return "";
    });
    const present_question = (question: string) => {
      context.emit("present_question", question);
    };

    /*
    const download_jpg = computed(() => {
      if (selected_item.value) {
        const jpgData =  base64ToUint8array(store.state.cy.jpg().split(",")[1]);
        const blob = new Blob([jpgData], {
          type: 'image/jpeg'
        });
        return window.URL.createObjectURL(blob);
      }
      return "";
    });
    */

    return {
      histories,
      selectGraph,
      setAttempt,
      attempt,
      cancel,
      confirm,
      publishing_id,
      toggleEditMode,
      isEditMode,
      uid,
      graphUpdated,
      title,
      selected_element,

      download_url,
      download_png,
      // download_jpg,
      downloadToggle,
      isDownloadOpen,
      fetch_graph_from_store,
      graph_fetched,
      select_data_down,
      present_question,
    };
  },
});
</script>
