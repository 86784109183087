<template>
  <div class="home">
    <div class="px-6 pt-2 gap-6 h-screen mb-12">
      <div class="w-3/4 h-screen bg-white rounded-md p-4 mx-auto text-left">
        <div class="text-center font-montserrat text-2xl">
          <h2>About InstaGraph</h2>
        </div>
        <p class="mt-4">
          This project was started by <a class="underline" href="https://twitter.com/yoheinakajima">Yohei Nakajima</a>, a venture capitalist and the inventor of
          <a class="underline" href="https://github.com/yoheinakajima/babyagi">BabyAGI</a>.
        </p>
        <p class="mt-4">
          We are turning his idea into a hosted web service, which allows people to create and share knowledge graphs, by simply typing the questions (such as
          "Solar System" or "Queen and its ten popular songs") and letting GPT to generate them.
        </p>
        <h3 class="my-4 font-bold">Related Links</h3>
        <ul class="list-disc ml-6">
          <li><a class="underline" href="https://github.com/yoheinakajima/instagraph" target="_blank">Original InstaGraph repository</a></li>
          <li>
            <a class="underline" href="https://github.com/InstaGraph/InstaGraph-Support" target="_blank">InstaGraph support repository</a> (if you have any bug
            reports or suggestions, please file GitHub Issues here)
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";

export default defineComponent({
  name: "AboutPage",
  setup() {
    useHead({
      title: "About page",
    });
    return {};
  },
});
</script>
