<template>
  <div>
    <div class="px-6 pt-2 gap-6 h-screen">
      <div class="text-center font-montserrat text-2xl">
        <h2>Latest Public Graph</h2>
      </div>
      <div class="w-full h-screen bg-white rounded-md pt-4">
        <GraphList />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import GraphList from "@/components/GraphList.vue";

export default defineComponent({
  components: {
    GraphList,
  },
});
</script>
