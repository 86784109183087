<template>
  <div>
    <div class="px-6 pt-2 gap-6 h-screen">
      <div class="w-full h-screen bg-white rounded-md pt-4">
        <div class="text-xl w-3/4 mx-auto my-8 opacity-60">Converts natural language prompts into a visualized knowlege graph (text2graph)</div>
        <div class="my-2">Please use your Google account to sign-in.</div>
        <div class="mt-4">
          <GoogleLogin />
        </div>
        <GraphList />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import GraphList from "@/components/GraphList.vue";
import GoogleLogin from "@/components/GoogleLogin.vue";

export default defineComponent({
  components: {
    GoogleLogin,
    GraphList,
  },
  setup() {},
});
</script>

<style>
#getWaitlistInnerContainer {
  margin: auto;
}
</style>
