<template>
  <div v-if="!isLoading && related_graphs.length === 0">No related graph</div>
  <div v-else>
    <div v-for="(graph, k) in related_graphs" :key="k" class="flex">
      <div class="truncate">
        <router-link :to="graph.location_path()"> {{ graph.data.message }} </router-link>
      </div>
      <div class="whitespace-nowrap">
        @
        <span v-if="users[graph.user_id]">
          <router-link :to="users[graph.user_id].location_path()">
            {{ (users[graph.user_id] || {}).nickname || "---" }}
          </router-link>
        </span>
        ({{ graph.created_at() }})
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";

import { db } from "@/utils/firebase";
import { collectionGroup, getDocs, collection, query, where, documentId } from "firebase/firestore";

import { GraphDocument, UserDocument } from "@/models";

import { arrayChunk, arrayUniq } from "@/utils/utils";

export default defineComponent({
  setup() {
    const route = useRoute();
    const graph_id = computed(() => route.params.graph_id);
    const users = ref<{ [key: string]: UserDocument }>({});
    const related_graphs = ref<GraphDocument[]>([]);
    const isLoading = ref(false);

    const load_user = async () => {
      const user_ids = arrayUniq(related_graphs.value.map((a) => a.user_id));
      if (user_ids.length > 0) {
        // console.log(user_ids);
        await Promise.all(
          arrayChunk(user_ids, 10).map(async (ids) => {
            // console.log(ids);
            const a = await getDocs(query(collection(db, `user`), where(documentId(), "in", ids)));
            if (!a.empty) {
              users.value = a.docs.reduce((tmp: { [key: string]: UserDocument }, b) => {
                const data = new UserDocument(b);
                tmp[b.id] = data;
                return tmp;
              }, {});
            }
          }),
        );
      }
    };
    const update_links = async () => {
      related_graphs.value = [];
      isLoading.value = true;
      const related_label_id: { [key: string]: boolean } = {};
      const labels = await getDocs(query(collectionGroup(db, "labeled_graph"), where("graph_id", "==", graph_id.value)));
      await Promise.all(
        labels.docs.map(async (labelDoc) => {
          const data = labelDoc.data();
          const a = await getDocs(collection(db, `label/${data.id}/labeled_graph`));
          a.docs.map((b) => {
            const data = b.data();
            const { graph_id: gid } = data;
            if (graph_id.value !== gid) {
              related_label_id[gid] = true;
            }
          });
        }),
      );
      if (Object.keys(related_label_id).length > 0) {
        await Promise.all(
          arrayChunk(Object.keys(related_label_id), 10).map(async (ids) => {
            const a = await getDocs(
              query(collectionGroup(db, `graph`), where("is_public", "==", true), where("is_deleted", "==", false), where("graph_id", "in", ids)),
            );
            related_graphs.value = a.docs.map((b) => new GraphDocument(b));
          }),
        );
      }
      await load_user();
      isLoading.value = false;
    };
    watch(
      graph_id,
      (v) => {
        related_graphs.value = [];
        if (v) {
          update_links();
        }
      },
      { immediate: true },
    );
    return {
      related_graphs,
      users,
      isLoading,
    };
  },
});
</script>
