<template>
  <div class="relative">
    <Loading v-if="is_loading" />
    <div ref="cyRef" class="w-full h-full" :class="is_loading ? 'opacity-30' : ''" />
    <div class="absolute top-0 m-2">
      <div>
        <select v-model="layout_value" class="border-2 p-1 rounded-md">
          <option v-for="(l, k) in layouts" :key="k">{{ l }}</option>
        </select>
        <button @click="update_layout" class="border-2 p-1 rounded-md">Update</button>
        |
        <button @click="fit" class="border-2 p-1 rounded-md">Fit</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted, PropType } from "vue";

import { useStore } from "vuex";

import { GraphData } from "@/models/graph";
import { clean_graph_data } from "@/utils/graph_utils";
import { sleep, resizeEndEventListener } from "@/utils/utils";

import Loading from "@/components/Loading.vue";

import cytoscape, {
  //  ElementDefinition,
  //  ElementsDefinition,
  Position,
  EventObject,
  Core,
  NodeSingular,
} from "cytoscape";
import fcose from "cytoscape-fcose";

cytoscape.use(fcose);

const layouts = ["grid", "cose", "random", "circle", "concentric", "fcose", "breadthfirst"];

const getTextColor = (bgColor = "#000000") => {
  const [r, g, b] = [0, 2, 4].map((start) => parseInt(bgColor.replace("#", "").substr(start, 2), 16));
  const brightness = r * 0.299 + g * 0.587 + b * 0.114;
  return brightness < 40 ? "#ffffff" : "#000000";
};

const calcNodeWidth = (label: string) => {
  if (label === null || label === undefined) {
    return "50px";
  }
  return Math.max(50, label.length * 8) + "px";
};

export default defineComponent({
  props: {
    graph_data: {
      type: Object as PropType<GraphData | null>,
      default: null,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loading,
  },
  emits: ["update_position", "select_graph"],
  setup(props, context) {
    const cyRef = ref();
    const store = useStore();
    const error_msg = ref<string>("");
    const layout_value = ref(layouts[0]);

    let cy: null | Core = null;
    const emit_positions = () => {
      if (cy) {
        const position: { [key: string]: Position } = {};
        cy.nodes().forEach(function (node) {
          position[node.id()] = node.position();
        });
        context.emit("update_position", position);
      }
    };
    const callback = (e: EventObject) => {
      if (e.type === ("select" as any)) {
        const node = e.target;
        const select_data = {
          group: node.group(),
          id: node.id(),
        };
        context.emit("select_graph", select_data);
      }
      if (e.type === "mouseup" || e.type === "touchend") {
        emit_positions();
      }
    };
    const createGraph = () => {
      try {
        cy = cytoscape({
          container: cyRef.value,
          style: [
            {
              selector: "node",
              style: {
                "background-color": "data(color)",
                label: "data(label)",
                "text-valign": "center",
                "text-halign": "center",
                shape: "rectangle",
                height: "50px",
                width: (ele: NodeSingular) => calcNodeWidth(ele.data("label")),
                color: (ele: NodeSingular) => getTextColor(ele.data("color")),
                "font-size": "12px",
              },
            },
            {
              selector: "edge",
              style: {
                width: 3,
                "line-color": "data(color)",
                "target-arrow-color": "data(color)",
                "target-arrow-shape": "none",
                label: "data(label)",
                "curve-style": "unbundled-bezier",
                "line-dash-pattern": [4, 4],
                "text-background-color": "#ffffff",
                "text-background-opacity": 1,
                "text-background-shape": "rectangle",
                "font-size": "10px",
              },
            },
            {
              selector: "edge[?directed]",
              style: {
                "target-arrow-shape": "triangle",
              },
            },
          ],
          layout: {
            name: "cose",
            fit: true,
            padding: 30,
            avoidOverlap: true,
          },
        });
        cy.on("mouseup", callback);
        cy.on("touchend", callback);
        cy.on("select", "node", callback);
        cy.on("select", "edge", callback);
        store.commit("setCytoscape", cy);
      } catch (error) {
        console.error(error);
        store.commit("setCytoscape", null);
        // NOTE: It's hard to render this error message consistently for some reason.
        error_msg.value = `${error}`;
      }
    };

    const data = computed(() => {
      if (props.graph_data) {
        return clean_graph_data(props.graph_data);
      }
      return null;
    });
    const updateGraphData = async () => {
      if (data.value && cy) {
        cy.elements().remove();
        cy.add(data.value.elements);
        const name = data.value.elements.nodes.reduce((name, node) => {
          if (node.position) {
            return "preset";
          }
          return name;
        }, "cose");
        cy.layout({ name }).run();
        cy.fit();
        if (name == "cose") {
          await sleep(400);
          emit_positions();
        }
      }
    };
    const update_layout = async () => {
      if (cy) {
        cy.layout({ name: layout_value.value }).run();
        cy.fit();
        await sleep(layout_value.value === "fcose" ? 2000 : 400);
        emit_positions();
      }
    };
    const fit = async () => {
      if (cy) {
        cy.fit();
      }
    };
    onMounted(() => {
      createGraph();
      if (data.value) {
        updateGraphData();
      }
    });
    watch(data, (value: GraphData | null) => {
      if (value) {
        updateGraphData();
        if (cy) {
          cy.resize();
        }
      } else if (cy) {
        cy.elements().remove();
        cy.layout({ name: "cose" }).run();
      }
    });
    const resizeFit = () => {
      if (cy) {
        cy.fit();
      }
    };
    resizeEndEventListener(resizeFit);

    return {
      cyRef,
      error_msg,
      update_layout,
      layout_value,
      layouts,
      fit,
    };
  },
});
</script>
