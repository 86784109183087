import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "absolute top-0 m-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.is_loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref: "cyRef",
      class: _normalizeClass(["w-full h-full", _ctx.is_loading ? 'opacity-30' : ''])
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.layout_value) = $event)),
          class: "border-2 p-1 rounded-md"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layouts, (l, k) => {
            return (_openBlock(), _createElementBlock("option", { key: k }, _toDisplayString(l), 1))
          }), 128))
        ], 512), [
          [_vModelSelect, _ctx.layout_value]
        ]),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.update_layout && _ctx.update_layout(...args))),
          class: "border-2 p-1 rounded-md"
        }, "Update"),
        _createTextVNode(" | "),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.fit && _ctx.fit(...args))),
          class: "border-2 p-1 rounded-md"
        }, "Fit")
      ])
    ])
  ]))
}