<template>
  <div v-if="user === undefined">loading...</div>
  <Home v-else-if="user" />
  <HomeNoLogin v-else />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUser } from "@/utils/utils";
import Home from "@/views/Home.vue";
import HomeNoLogin from "@/views/HomeNoLogin.vue";

export default defineComponent({
  components: {
    Home,
    HomeNoLogin,
  },
  setup() {
    const user = useUser();
    return {
      user,
    };
  },
});
</script>
