<template>
  <div class="flex cursor-pointer" @click="selectGraph(history)" :class="is_selected ? 'bg-blue-500' : ''">
    <div class="grow text-left truncate px-4 text-ellipsis white-space-nowrap">
      <span :class="is_selected ? 'text-white' : ''">
        {{ history.data.message }}
      </span>
    </div>
    <div class="text-opacity-40 flex-none w-8 pr-2" :class="is_selected ? 'text-white' : 'text-slate-500'">
      <public_or_private :is_public="history.data.is_public" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import GraphDocument from "@/models/graph";

import public_or_private from "@/components/icons/public_or_private.vue";

export default defineComponent({
  props: {
    history: {
      type: Object as PropType<GraphDocument>,
      required: true,
    },
    is_selected: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    public_or_private,
  },
  emits: ["selectGraph"],
  setup(_, context) {
    const selectGraph = (history: GraphDocument) => {
      context.emit("selectGraph", history);
    };
    return {
      selectGraph,
    };
  },
});
</script>
