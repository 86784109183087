<template>
  <div v-if="graph_data">
    <div class="w-full m-auto fixed top-0 z-0"></div>
    <Loading v-if="isCopying" />
    <div class="flex" :class="isCopying ? 'opacity-30' : ''">
      <div class="w-1/12">&nbsp;</div>
      <div class="w-9/12 m-auto">
        <div class="bg-white rounded-md mt-4">
          <div v-if="graph_data.title || user_data" class="text_center break-words">
            <span> {{ graph_data.title || "A knowledge graph" }} </span>
            <span v-if="user_data">
              by <router-link :to="user_data.location_path()">{{ user_data?.nickname }}</router-link>
            </span>
          </div>
          <div :class="message_is_open ? '' : 'truncate'" @click="message_toggle" class="break-words">
            {{ message }}
          </div>
          <div v-if="graph_data.related_url" class="break-words underline">
            <a :href="graph_data.related_url">{{ graph_data.related_url }}</a>
          </div>
        </div>
        <!-- main -->
        <div class="w-full bg-white rounded-md mt-4 mx-auto">
          <Graph class="w-full mr-6 h-screen" :graph_data="graph_data.graph_data" @select_graph="selectGraph" />
        </div>
        <div class="w-full mt-4 mx-auto">
          <div class="text-left px-6 mb-2 font-bold text-opacity-60">List of related graphs</div>
          <div class="px-6 py-2 w-full bg-white rounded-md text-left mb-8">
            <RelatedGraph />
          </div>
        </div>
      </div>
      <div class="w-2/12">
        <div class="sticky top-0">
          <div v-if="!isSignedIn">
            <router-link to="/">
              <div class="mt-4 font-bold">Get Started<br /></div>
            </router-link>
          </div>
          <a :href="`https://twitter.com/intent/tweet?text=${twitter_text}&url=${url}&hashtags=instagraph`" target="_blank" rel="noopener noreferrer">
            <div>
              <x_icon class="w-10 mr-4 mt-2" :link="`https://twitter.com/intent/tweet?text=${twitter_text}&url=${url}`" />
            </div>
          </a>
          <div v-if="isSignedIn">
            <button @click="call_copy_graph" class="border-2 p-1 rounded-md mt-4">Import Graph</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import Graph from "@/components/graph.vue";
import Loading from "@/components/Loading.vue";
import RelatedGraph from "@/components/PublicRelatedGraph.vue";
import x_icon from "@/components/icons/x_icon.vue";

import { db } from "@/utils/firebase";
import { getDoc, doc } from "firebase/firestore";

import { GraphDocumentData, SelectData } from "@/models/graph";
import UserDocument from "@/models/user";
import { setPageTitle, useToggle, useIsSignedIn } from "@/utils/utils";
import { copy_graph } from "@/utils/functions";
import { splitMessages } from "@/utils/common";

export default defineComponent({
  components: {
    Loading,
    Graph,
    RelatedGraph,
    x_icon,
  },
  setup() {
    const isSignedIn = useIsSignedIn();
    const route = useRoute();
    const router = useRouter();

    const graph_id = computed(() => route.params.graph_id as string);
    const user_id = computed(() => route.params.user_id as string);

    const graph_data = ref<GraphDocumentData | undefined>();
    const user_data = ref<UserDocument | undefined>();

    const message = ref("");

    const { toggle: message_toggle, value: message_is_open } = useToggle(false);

    const load_graph = async () => {
      const graphDoc = await getDoc(doc(db, `user/${user_id.value}/graph/${graph_id.value}`));
      if (graphDoc) {
        const data = graphDoc.data() as GraphDocumentData;
        if (data.positions) {
          data.graph_data.elements.nodes.forEach((node) => {
            node.position = data.positions[node.data.id!];
          });
        }
        graph_data.value = data;
        message.value = splitMessages(graph_data.value.message, 2);
      }
    };

    const load_user = async () => {
      const userDoc = await getDoc(doc(db, `user/${user_id.value}`));
      if (userDoc && userDoc.exists()) {
        const data = new UserDocument(userDoc);
        user_data.value = data;
      }
    };

    watch(graph_data, (v) => {
      if (v) {
        setPageTitle(v.message);
      }
    });

    watch(
      [graph_id, user_id],
      (v: string[]) => {
        if (v[0] && v[1]) {
          load_graph();
          load_user();
        }
      },
      { immediate: true },
    );

    // LATER: Similar code in models/graph.ts
    const twitter_text = computed(() => {
      const title = graph_data.value && graph_data.value.title ? graph_data.value.title : "A knowledge graph";
      return `${title} generated by InstaGraph.`;
    });

    const selectGraph = (_select_data: SelectData) => {
      // TODO
      console.log(_select_data);
    };

    const isCopying = ref(false);
    const call_copy_graph = async () => {
      isCopying.value = true;
      try {
        const result = await copy_graph({
          user_id: user_id.value,
          graph_id: graph_id.value,
        });
        if (result.data.result) {
          router.push("/");
        } else {
          console.error(result);
        }
      } catch (e) {
        console.log(e);
      }
      isCopying.value = false;
    };

    return {
      graph_data,
      user_data,
      url: location.href,
      twitter_text,

      message_toggle,
      message_is_open,
      message,
      isSignedIn,
      selectGraph,

      call_copy_graph,

      isCopying,
    };
  },
});
</script>
