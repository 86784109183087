<template>
  <div class="layout">
    <div class="bg-warmgray-400 flex h-full flex-col bg-opacity-20">
      <div class="w-full flex-1 bg-gray-100">
        <HeaderMeun />
        <div class="top-0 w-full sm:relative">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { auth } from "@/utils/firebase";
import { User } from "firebase/auth";

import { useI18nParam } from "@/i18n/utils";
import { analytics } from "@/utils/firebase";
import { setCurrentScreen } from "firebase/analytics";

import HeaderMeun from "@/components/HeaderMenu.vue";

interface UserData {
  user: User | null;
}

export default defineComponent({
  name: "AppLayout",
  components: {
    HeaderMeun,
  },
  async setup() {
    const store = useStore();
    const route = useRoute();

    const user = reactive<UserData>({ user: null });

    useI18nParam();

    onMounted(() => {
      auth.onAuthStateChanged((fbuser) => {
        if (fbuser) {
          console.log("authStateChanged:");
          user.user = fbuser;
          store.commit("setUser", fbuser);
        } else {
          store.commit("setUser", null);
        }
      });
    });

    const pingAnalytics = () => {
      console.log("ping");
      // TODO
      setCurrentScreen(analytics, document.title);
    };

    watch(
      () => route.path,
      () => {
        // https://support.google.com/analytics/answer/9234069?hl=ja
        pingAnalytics();
      },
    );

    return {
      user,
    };
  },
});
</script>
