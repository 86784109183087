import { DateTime } from "luxon";

import { ElementsDefinition } from "cytoscape";
import { QueryDocumentSnapshot, DocumentSnapshot, DocumentData, Timestamp } from "firebase/firestore";
import FirebaseModel from "./firebasemodel";

export type SelectData = { id: string; group: string };

export type GraphData = { elements: ElementsDefinition };
export interface GraphDocumentData extends DocumentData {
  uid: string;
  graph_id: string;
  message: string;
  graph_data: GraphData;
  is_deleted: boolean;
  is_public: boolean;
  created_at: Timestamp;
  title: string;
  related_url: string;
}

export default class GraphDocument extends FirebaseModel<GraphDocumentData> {
  graph_id: string;
  user_id: string;
  graph_data: GraphData;
  constructor(_model: QueryDocumentSnapshot | DocumentSnapshot<GraphDocumentData>) {
    super(_model);
    this.graph_id = this.data.graph_id;
    this.graph_data = this.data.graph_data;
    this.user_id = this.data.uid;
  }
  location_path() {
    return `/graph/${this.data.uid}/${this.graph_id}`;
  }
  public_url() {
    return `${window.location.origin}${this.location_path()}`;
  }
  json_binary() {
    const jsonString = JSON.stringify(this.data.graph_data, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    return blob;
  }
  created_at() {
    return DateTime.fromJSDate(this.data.created_at.toDate()).toLocaleString(DateTime.DATETIME_SHORT);
  }
  twitter_text() {
    const title = this.data.title || "A knowledge graph";
    return `${title} generated by InstaGraph.`;
  }
}
