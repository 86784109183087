import { createStore } from "vuex";
import { User } from "firebase/auth";
import cytoscape from "cytoscape";
import { GraphDocument } from "@/models";

interface State {
  user: User | null | undefined;
  cy: cytoscape.Core | null;
  updatedGraph: GraphDocument | null;
}

export default createStore<State>({
  state: {
    user: undefined,
    cy: null,
    updatedGraph: null,
  },
  mutations: {
    setUser(state: State, user: User | null) {
      state.user = user;
    },
    setCytoscape(state: State, cy: cytoscape.Core | null) {
      state.cy = cy;
    },
    setUpdatedGraph(state: State, graph: GraphDocument | null) {
      console.log("*** setUpdatedGraph called", graph);
      state.updatedGraph = graph;
    },
  },
  getters: {
    isSignedIn: (state: State) => {
      return state.user !== null && state.user !== undefined;
    },
  },
  actions: {},
  modules: {},
});
