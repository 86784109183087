import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const base64ToUint8array = (base64_image: string) => {
  return new Uint8Array([...atob(base64_image)].map((s) => s.charCodeAt(0)));
};

export const upload_base64_png = async (base64_image: string, user_id: string, graph_id: string): Promise<string | null> => {
  const storage = getStorage();

  const bytes_image = base64ToUint8array(base64_image);
  const path = `/graph/images/${user_id}/${graph_id}/graph.png`;
  const storageRef = ref(storage, path);

  try {
    const metadata = {
      contentType: "image/png",
    };

    const url: string = await new Promise((resolve, rejected) => {
      uploadBytes(storageRef, bytes_image, metadata)
        .then((snapshot) => {
          console.log("Uploaded an array!");
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            // console.log('File available at', downloadURL);
            resolve(downloadURL);
          });
        })
        .catch((e) => {
          rejected(e);
        });
    });
    return url;
  } catch (e) {
    return null;
  }

  // console.log(bytes_image, user_id, graph_id);
};
