import { QueryDocumentSnapshot, DocumentSnapshot, DocumentData, Timestamp } from "firebase/firestore";
import FirebaseModel from "./firebasemodel";

export interface UserDocumentData extends DocumentData {
  user_id: string;
  nickname: string;
  profile: string;
  updated_at: Timestamp;
}

export default class UserDocument extends FirebaseModel<UserDocumentData> {
  nickname: string;
  profile: string;

  constructor(_model: QueryDocumentSnapshot | DocumentSnapshot) {
    super(_model);
    this.nickname = this.data.nickname || "";
    this.profile = this.data.profile || "";
  }
  location_path() {
    return `/user/${this.id}`;
  }
}
