import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-4 py-1 w-1/2 inline-flex items-center justify-center bg-red-400 text-white rounded-lg"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.googleSignin && _ctx.googleSignin(...args))),
        class: "inline-flex h-12 items-center justify-center rounded-lg bg-black bg-opacity-5 px-6 shadow-lg hover:bg-blue-600 hover:text-white"
      }, " SignIn with Google account ")
    ]),
    (_ctx.error_message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.error_message === 'Unauthorized_email')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, " The Google account you tried to SignIn to has not been invited yet. Please try again with the correct Google account or wait until you are invited. "))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.error_message), 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}