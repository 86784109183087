import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-2 overflow-x-hidden" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "bg-blue-50 py-2"
}
const _hoisted_5 = {
  key: 0,
  class: "w-full h-3 bg-white rounded-md mt-4 loading block px-2"
}
const _hoisted_6 = {
  key: 0,
  class: "my-4"
}
const _hoisted_7 = {
  key: 2,
  class: "px-2 py-1 mt-2 mb-2 bg-white rounded-lg"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryElement = _resolveComponent("HistoryElement")!
  const _component_HistoryButtons = _resolveComponent("HistoryButtons")!
  const _component_GraphEditor = _resolveComponent("GraphEditor")!
  const _component_ConfirmPanel = _resolveComponent("ConfirmPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.histories, (history) => {
      return (_openBlock(), _createElementBlock("div", {
        key: history.graph_id
      }, [
        (history.data.graph_id == _ctx.graph_id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                ref_for: true,
                ref: "selected_element"
              }, [
                _createVNode(_component_HistoryElement, {
                  onSelectGraph: _ctx.selectGraph,
                  history: history,
                  is_selected: true
                }, null, 8, ["onSelectGraph", "history"])
              ], 512)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_HistoryElement, {
                onSelectGraph: _ctx.selectGraph,
                history: history,
                is_selected: false
              }, null, 8, ["onSelectGraph", "history"])
            ])),
        (history.data.graph_id == _ctx.graph_id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_HistoryButtons, {
                onDownloadToggle: _ctx.downloadToggle,
                onToggleEditMode: _ctx.toggleEditMode,
                onSetAttempt: _ctx.setAttempt,
                is_edit_mode: _ctx.isEditMode,
                history: history,
                class: "px-2"
              }, null, 8, ["onDownloadToggle", "onToggleEditMode", "onSetAttempt", "is_edit_mode", "history"]),
              (!history.data.is_public && _ctx.publishing_id == history.data.graph_id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, "   "))
                : _createCommentVNode("", true),
              _createVNode(_component_GraphEditor, {
                graph_id: _ctx.graph_id,
                uid: _ctx.uid,
                is_edit_mode: _ctx.isEditMode,
                select_data: _ctx.select_data_down,
                fetch_graph_from_store: _ctx.fetch_graph_from_store,
                onGraph_updated: _ctx.graphUpdated,
                onGraph_fetched: _ctx.graph_fetched,
                onPresent_question: _ctx.present_question
              }, null, 8, ["graph_id", "uid", "is_edit_mode", "select_data", "fetch_graph_from_store", "onGraph_updated", "onGraph_fetched", "onPresent_question"]),
              (_ctx.attempt)
                ? (_openBlock(), _createBlock(_component_ConfirmPanel, {
                    key: 1,
                    message_id: `history.may_${_ctx.attempt}`,
                    yes_id: `history.yes_${_ctx.attempt}`,
                    is_cc: _ctx.attempt === `publish`,
                    no_id: "history.cancel",
                    onYes: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm())),
                    onNo: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => [
                      (_ctx.attempt === 'publish')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createTextVNode(" Title "),
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
                              class: "bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full px-2"
                            }, null, 512), [
                              [_vModelText, _ctx.title]
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["message_id", "yes_id", "is_cc"]))
                : _createCommentVNode("", true),
              (_ctx.isDownloadOpen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("a", {
                      href: _ctx.download_url,
                      class: "underline",
                      download: "InstaGraph.json"
                    }, " JSON data ", 8, _hoisted_8),
                    _createTextVNode(" / "),
                    _createElementVNode("a", {
                      href: _ctx.download_png,
                      class: "underline",
                      download: "InstaGraph.png"
                    }, " PNG image ", 8, _hoisted_9)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}