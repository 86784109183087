import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "truncate" }
const _hoisted_4 = { class: "whitespace-nowrap" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.isLoading && _ctx.related_graphs.length === 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "No related graph"))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.related_graphs, (graph, k) => {
          return (_openBlock(), _createElementBlock("div", {
            key: k,
            class: "flex"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_router_link, {
                to: graph.location_path()
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(graph.data.message), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createTextVNode(" @ "),
              (_ctx.users[graph.user_id])
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createVNode(_component_router_link, {
                      to: _ctx.users[graph.user_id].location_path()
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString((_ctx.users[graph.user_id] || {}).nickname || "---"), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                : _createCommentVNode("", true),
              _createTextVNode(" (" + _toDisplayString(graph.created_at()) + ") ", 1)
            ])
          ]))
        }), 128))
      ]))
}