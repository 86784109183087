export const firebaseConfig = {
  apiKey: "AIzaSyBBrKj7Jg0pcGwz1NLrsnv4m4aXkggn6cQ",
  authDomain: "instagraph-prod.firebaseapp.com",
  projectId: "instagraph-prod",
  storageBucket: "instagraph-prod.appspot.com",
  messagingSenderId: "707757134846",
  appId: "1:707757134846:web:4208de7b070950db4066af",
  measurementId: "G-5QK1WE61KQ",
};

export const sentryConfig = {
  dsn: "https://0f87032e2efa5c50480717eff882b630@o4506203339096064.ingest.sentry.io/4506203625684992",
  tracePropagationTargets: ["localhost", /^https:\/\/us-central1-instagraph-prod\.cloudfunctions\.net\//],
};
