import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center rounded-lg bg-blue-100 px-4 py-2 hover:bg-blue-300" }
const _hoisted_2 = { class: "inline-flex items-center justify-center" }
const _hoisted_3 = { class: "material-icons text-warmgray-600 mr-2 text-lg" }
const _hoisted_4 = { class: "text-warmgray-600 text-sm font-bold" }
const _hoisted_5 = {
  key: 1,
  class: "flex items-center rounded-lg bg-blue-100 px-4 py-2 hover:bg-blue-300"
}
const _hoisted_6 = { class: "inline-flex items-center justify-center" }
const _hoisted_7 = { class: "material-icons text-warmgray-600 mr-2 text-lg" }
const _hoisted_8 = { class: "text-warmgray-600 text-sm font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.link)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.localizedUrl(_ctx.link)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.icon), 1),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.title)), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.icon), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(_ctx.title)), 1)
          ])
        ]))
  ]))
}