import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-left px-2 py-0.5 mt-2 mb-2 bg-white rounded-lg" }
const _hoisted_2 = { class: "my-4" }
const _hoisted_3 = {
  key: 0,
  href: "https://creativecommons.org/public-domain/cc0/",
  class: "text-xs opacity-400 underline",
  target: "_blank",
  rel: "noopener noreferrer"
}
const _hoisted_4 = { class: "my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t(_ctx.message_id)) + " ", 1),
      (_ctx.is_cc)
        ? (_openBlock(), _createElementBlock("a", _hoisted_3, _toDisplayString(_ctx.$t("history.about_cc")), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "mx-2 py-2 px-4 text-white rounded cursor-pointer bg-blue-500",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitYes && _ctx.emitYes(...args)))
      }, _toDisplayString(_ctx.$t(_ctx.yes_id)), 1),
      _createElementVNode("button", {
        class: "mx-2 py-2 px-4 text-white rounded cursor-pointer bg-slate-500",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitNo && _ctx.emitNo(...args)))
      }, _toDisplayString(_ctx.$t(_ctx.no_id)), 1)
    ])
  ]))
}