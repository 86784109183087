import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Home = _resolveComponent("Home")!
  const _component_HomeNoLogin = _resolveComponent("HomeNoLogin")!

  return (_ctx.user === undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "loading..."))
    : (_ctx.user)
      ? (_openBlock(), _createBlock(_component_Home, { key: 1 }))
      : (_openBlock(), _createBlock(_component_HomeNoLogin, { key: 2 }))
}