<template>
  <div class="home">
    <div class="md:px-3 lg:px-6 pt-2 gap-2 lg:gap-6 h-screen">
      <div>
        <form class="flex items-center p-2 md:p-6 gap-2 md:gap-6 self-stretch rounded-md" :class="bg_color" @submit.prevent="handleFormSubmit">
          <label for="userInput" class="block mt-1 mb-auto select-none">
            <span class="text-2xl font-medium">{{ $t("home.question") }}</span
            ><br /><span>Text2Graph</span>
          </label>
          <div class="flex-grow">
            <textarea
              type="text"
              class="w-full p-2 border rounded-md"
              v-model="userInput"
              :placeholder="$t('home.user_input_question')"
              :disabled="isLoading"
              :rows="textRows"
            />
            <div class="mt-1 text-left">
              <input
                type="checkbox"
                value="foo"
                :checked="mergeGraph"
                :disabled="!selected_graph_id"
                @change="toggleMergeGraph"
                class="bg-gray-50 border border-gray-300 mr-2"
                id="mergeGraph"
              />
              <label for="mergeGraph" class="select-none" :class="!selected_graph_id ? 'text-gray-300' : ''">{{ $t("home.merge_graph") }}</label>
            </div>
          </div>
          <input
            type="submit"
            value="Submit"
            class="py-2 px-4 text-white rounded cursor-pointer block mt-0 mb-auto"
            :class="isLoading ? 'bg-blue-200' : 'bg-blue-500'"
            :disabled="isLoading"
          />
        </form>
      </div>

      <!-- loading -->
      <div class="w-full h-3 bg-white rounded-md mt-4" :class="isLoading ? 'loading block' : 'hidden'">&nbsp;</div>
      <!-- error -->
      <div v-if="serverError" class="w-full bg-red-400 rounded-md mt-4 text-white">
        <div>
          {{ $t(`home.${serverError}`) }}
        </div>
        <div v-if="serverError === 'openai_down_error'">
          <a href="https://status.openai.com/" target="_blank" rel="noopener noreferrer">https://status.openai.com/</a>
        </div>
      </div>
      <!-- main -->
      <div class="h-2/3">
        <div class="mt-2 md:mt-4 flex h-full">
          <div class="flex w-2/3">
            <Graph
              class="flex-col w-full bg-white rounded-md mr-6"
              :graph_data="graph_data"
              @update_position="updatePosition"
              @select_graph="selectGraph"
              :is_loading="isLoading"
            />
          </div>
          <history
            class="w-1/3 bg-white rounded-md overflow-y-scroll"
            v-if="user"
            :graph_id="selected_graph_id"
            @update_graph_data="setGraphData"
            @present_question="present_question"
            :select_data="select_data"
          />
        </div>
      </div>
      <!-- Related Graph -->
      <div>
        <div class="mt-2 md:mt-4 flex h-20">
          <div class="flex w-full bg-white rounded-md text-left">
            <RelatedGraph
              if="user && selected_graph_id"
              class="overflow-y-scroll w-full"
              :uid="user.uid"
              :graph_id="selected_graph_id"
              @update_graph_data="setGraphData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import Graph from "@/components/graph.vue";
import History from "@/components/history/list.vue";

import { call_graph } from "@/utils/functions";
import { useUser, useToggle } from "@/utils/utils";
import { FunctionGraphData } from "@/utils/functions_type";
import { SelectData, GraphDocumentData } from "@/models/graph";
import RelatedGraph from "@/components/UserRelatedGraph.vue";
import { Position } from "cytoscape";
import { load_positions, set_positions } from "@/utils/position";
import isUrl from "validator/lib/isURL";
import { v4 as uuidv4 } from "uuid";
import { useStore } from "vuex";

export default defineComponent({
  name: "HomePage",
  components: {
    Graph,
    History,
    RelatedGraph,
  },
  setup() {
    const store = useStore();
    const userInput = ref("");
    const isLoading = ref(false);
    const graph_data = ref<FunctionGraphData | null>(null);
    const selected_graph_id = ref("");
    const user = useUser();
    const serverError = ref("");
    const select_data = ref<SelectData | undefined>();
    const related_url = ref("");
    const is_graph_public = ref<boolean>(false);
    const hiliteForm = ref<boolean>(false);

    const { toggle: toggleMergeGraph, value: mergeGraph } = useToggle(false);

    watch(selected_graph_id, () => {
      mergeGraph.value = false;
    });
    const textRows = computed(() => {
      const lineNumber = userInput.value.split("\n").length;
      if (lineNumber > 20) {
        return 20;
      }
      if (lineNumber < 2) {
        return 2;
      }
      return lineNumber + 1;
    });
    const handleFormSubmit = async () => {
      serverError.value = "";
      if (hasError.value) {
        return;
      }
      isLoading.value = true;
      try {
        const data = (() => {
          if (graph_data.value) {
            const message = (() => {
              if (userInput.value[0] === "*" || userInput.value[0] === "+") {
                return userInput.value.substring(1);
              }
              return userInput.value;
            })();
            const post_type = (() => {
              if (userInput.value[0] === "*") {
                return "update";
              }
              if (userInput.value[0] === "+" || mergeGraph.value) {
                return "merge";
              }
              return null;
            })();
            // TODO validate related url
            if (post_type) {
              return {
                message,
                options: { option_type: post_type, graph: postGraphData.value, related_url: related_url.value, skip_update: !is_graph_public.value },
              };
            }
          }
          return { message: userInput.value, options: { related_url: related_url.value } };
        })();

        if (!data.options.option_type) {
          graph_data.value = null;
          selected_graph_id.value = "";
        }

        if (data.message.length == 0) {
          isLoading.value = false;
          // LATER: Disable the UI so that we won't come here
          return;
        }
        const graphDataResponse = await call_graph(data);
        if (!graphDataResponse || !graphDataResponse.data.result) {
          isLoading.value = false;
          // LATER: User the error code from the server.
          console.log(graphDataResponse?.data);
          if (!graphDataResponse.data.result) {
            if (graphDataResponse.data.error.code === 503) {
              serverError.value = "openai_down_error";
              return;
            }
          }
          serverError.value = "no_graph_error";
          return;
        }
        console.log(graphDataResponse.data);

        if (data.options.skip_update) {
          // Merging: We pass the updated graph to GraphEditor (via History)
          store.commit("setUpdatedGraph", graphDataResponse.data.graph_data);
        } else {
          // New Graph: Update the display and let the History know it.
          graph_data.value = graphDataResponse.data.graph_data;
          is_graph_public.value = false;
          selected_graph_id.value = graphDataResponse.data.graph_id;
        }
      } catch (e) {
        console.log(e);
        serverError.value = "firebase_exception";
      }
      isLoading.value = false;
    };

    const placeHolderKey = computed(() => {
      if (related_url.value && isUrl(related_url.value, { require_protocol: true })) {
        return "home.placeholder_summary";
      }
      return "home.user_input_question";
    });

    const isValidData = computed(() => {
      const related_url_data = (() => {
        // console.log(related_url.value, isUrl(related_url.value));
        if (related_url.value !== "") {
          return isUrl(related_url.value, { require_protocol: true });
        }
        return true;
      })();
      return {
        related_url_data,
      };
    });
    const hasError = computed(() => {
      return Object.values(isValidData.value).some((a) => !a);
    });

    const postGraphData = computed(() => {
      if (graph_data.value) {
        const elements = graph_data.value.elements;
        const id2id: Record<string, string> = {}; // id mapping
        const nodes = elements.nodes.map((node) => {
          const { id, label, type, color } = node.data;
          const updatedId = uuidv4();
          id2id[id!] = updatedId;
          return { id: updatedId, label, type, color };
        });
        const edges = elements.edges.map((edge) => {
          const { source, target, label, directed, color } = edge.data;
          return {
            from: id2id[source],
            to: id2id[target],
            relationship: label,
            directed,
            color,
          };
        });
        return { nodes, edges };
      }
      return null;
    });

    const setGraphData = (graph: GraphDocumentData | null) => {
      serverError.value = "";
      if (graph === null) {
        userInput.value = "";
        graph_data.value = null;
        selected_graph_id.value = "";
        is_graph_public.value = false;
      } else {
        const positions = load_positions(graph.graph_id);
        if (positions) {
          graph.graph_data.elements.nodes.forEach((node) => {
            const position = positions[node.data.id!];
            if (position) {
              node.position = position;
            }
          });
        }
        userInput.value = graph.message;
        graph_data.value = graph.graph_data;
        is_graph_public.value = graph.is_public;
        selected_graph_id.value = graph.graph_id;
      }
    };
    const updatePosition = (position: { [key: string]: Position }) => {
      set_positions(selected_graph_id.value, position);
    };
    const selectGraph = (_select_data: SelectData) => {
      select_data.value = _select_data;
    };
    const present_question = (question: string) => {
      userInput.value = question;
      mergeGraph.value = true;
      hiliteForm.value = true;
    };
    watch(hiliteForm, (newValue) => {
      if (newValue) {
        setTimeout(() => {
          hiliteForm.value = false;
        }, 500);
      }
    });
    const bg_color = computed(() => {
      return hiliteForm.value ? "bg-blue-200" : "bg-white";
    });

    return {
      userInput,
      isLoading,
      handleFormSubmit,
      graph_data,
      selected_graph_id,
      user,
      setGraphData,
      updatePosition,
      serverError,

      selectGraph,
      select_data,

      related_url,

      isValidData,
      placeHolderKey,
      mergeGraph,
      toggleMergeGraph,

      textRows,
      present_question,
      bg_color,
    };
  },
});
</script>
