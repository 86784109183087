import { functions } from "@/utils/firebase";
import { httpsCallable } from "firebase/functions";
import {
  CallGraphData,
  CallGraphResult,
  CopyGraphData,
  CopyGraphResult,
  MergeUserGraphData,
  MergeUserGraphResult,
  MakePublicData,
  FunctionsError,
  FunctionGeneralResult,
} from "@/utils/functions_type";

export const testFunctions = httpsCallable(functions, "test");

export const call_graph = httpsCallable<CallGraphData, CallGraphResult | FunctionsError>(functions, "call_graph");
export const copy_graph = httpsCallable<CopyGraphData, CopyGraphResult | FunctionsError>(functions, "copy_graph");
export const merge_user_graph = httpsCallable<MergeUserGraphData, MergeUserGraphResult | FunctionsError>(functions, "merge_user_graph");

export const make_public = httpsCallable<MakePublicData, FunctionGeneralResult | FunctionsError>(functions, "make_public");
