import languages from "./languages";

const lang = {
  message: {
    hello: "hello world",
  },
  languages,
  menu: {
    top: "Home",
    signin: "SignIn",
    signout: "SignOut",
    config: "Config",
    about: "About",
    list: "Public graphs",
  },
  history: {
    publish: "Publish",
    share: "Share",
    public_url: "Public URL",
    download: "Download",
    delete: "Delete",
    edit: "Edit",
    may_publish: "Once you share it, anybody can access it and even remix with it (CC0). Do you agree to make it publically accessible?",
    may_delete: "Are you sure that you want to delete this knowledge graph?",
    yes_publish: "Agree",
    yes_delete: "Delete",
    cancel: "Cancel",
    add_node: "Add New Node",
    add_edge: "Add New Edge",
    delete_node: "Delete",
    may_delete_node: "Are you sure that you want to delete this node, and all associated edges?",
    may_delete_edge: "Are you sure that you want to delete this edge?",
    color: "Color",
    type: "Type",
    direction: "Directed",
    about_cc: "About CC0",
  },
  editor: {
    url: "URL",
    title: "Title",
    nodes: "Nodes",
    edges: "Edges",
    sort: "Sort",
    details: "Details",
    details_of: "Details about {node}",
  },
  home: {
    question: "Question",
    advanced: "Advanced...",
    no_graph_error: "No graph was generated. Please rephrase your question and try again.",
    openai_down_error: "OpenAI server error. If errors occur continuously, OpenAI server may be down.",
    firebase_exception: "Unexpected firebase exception. Please try again",
    user_input_question: "Ask any question like 'Solar system' or 'Nuclear reactor'",
    placeholder_summary: "The summary of the website or the web resource specified by the URL",
    url_sample: "https://example.com/sample123.html (optional)",
    merge_graph: "Merge with the selected graph",
  },
};

export default lang;
