import { EdgeDefinition, NodeDefinition } from "cytoscape";
import { GraphData } from "@/models/graph";

export const clean_graph_data = (graph_data: GraphData) => {
  const { elements } = graph_data;
  const { edges, nodes } = elements;
  const node_ids = nodes.reduce((tmp: { [key: string]: boolean }, node: NodeDefinition) => {
    tmp[node.data.id || ""] = true;
    return tmp;
  }, {});

  const new_edges = edges.filter((edge: EdgeDefinition) => {
    const { source, target } = edge.data;
    return node_ids[source] && node_ids[target];
  });

  const new_data = { ...graph_data };
  new_data.elements.edges = new_edges;
  return new_data;
};
