<template>
  <div>
    <div class="w-4/5 bg-white rounded-md mt-4 mx-auto h-screen mb-12">
      <div>User</div>
      <div v-if="user">
        <div>
          {{ user.nickname }}
        </div>
        <div>
          {{ user.profile }}
        </div>
        <div>
          <GraphList :user_id="user_id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from "vue";
import { useRoute } from "vue-router";

import GraphList from "@/components/GraphList.vue";
import { UserDocumentData } from "@/models/user";

import { db } from "@/utils/firebase";
import { getDoc, doc } from "firebase/firestore";

export default defineComponent({
  components: {
    GraphList,
  },
  setup() {
    const route = useRoute();
    const user_id = computed(() => route.params.user_id as string);
    const user = ref<UserDocumentData | null>();

    const load_user = async () => {
      const userDoc = await getDoc(doc(db, `user/${user_id.value}`));
      if (userDoc) {
        const data = userDoc.data() as UserDocumentData;
        user.value = data || { nickname: "", profile: "" };
      }
    };
    watch(
      user_id,
      (v) => {
        if (v) {
          load_user();
        } else {
          user.value = null;
        }
      },
      { immediate: true },
    );

    return {
      user_id,
      user,
    };
  },
});
</script>
