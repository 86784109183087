import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap w-7/8 mt-8 justify-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.graphs, (graph, k) => {
      return (_openBlock(), _createElementBlock("div", {
        key: k,
        class: "flex"
      }, [
        _createVNode(_component_router_link, {
          to: graph.location_path()
        }, {
          default: _withCtx(() => [
            (graph.data.thumbnail_url)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: graph.data.thumbnail_url,
                  class: "w-40"
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ]))
}