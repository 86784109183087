<template>
  <div class="text-left px-2 py-0.5 mt-2 mb-2 bg-white rounded-lg">
    <slot />
    <div class="my-4">
      {{ $t(message_id) }}
      <a href="https://creativecommons.org/public-domain/cc0/" v-if="is_cc" class="text-xs opacity-400 underline" target="_blank" rel="noopener noreferrer">
        {{ $t("history.about_cc") }}
      </a>
    </div>
    <div class="my-4">
      <button class="mx-2 py-2 px-4 text-white rounded cursor-pointer bg-blue-500" @click="emitYes">
        {{ $t(yes_id) }}
      </button>
      <button class="mx-2 py-2 px-4 text-white rounded cursor-pointer bg-slate-500" @click="emitNo">
        {{ $t(no_id) }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["yes", "no"],
  props: {
    message_id: {
      type: String,
      required: true,
    },
    is_cc: {
      type: Boolean,
      required: false,
    },
    yes_id: {
      type: String,
      required: true,
    },
    no_id: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const emitYes = () => emit("yes");
    const emitNo = () => emit("no");
    return {
      emitYes,
      emitNo,
    };
  },
});
</script>
