import { ref, computed, onUnmounted, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import router from "@/router";
import { Unsubscribe } from "firebase/firestore";

export const useUser = () => {
  const store = useStore();
  const user = computed(() => store.state.user);
  return user;
};
export const useIsSignedIn = () => {
  const store = useStore();
  const isSignedIn = computed(() => store.getters.isSignedIn);
  return isSignedIn;
};

export const useLang = () => {
  const i18n = useI18n();

  const lang = computed(() => {
    return i18n.locale.value;
  });

  const localizedUrl = (path: string) => {
    if (lang.value) {
      return `/${lang.value}` + path;
    }
    return path;
  };

  return {
    lang,
    localizedUrl,
  };
};

export const sleep = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export const useLocalizedRoute = () => {
  const { localizedUrl } = useLang();

  return (path: string) => {
    router.push(localizedUrl(path));
  };
};

export const noLoginPage = (redirect_path: string) => {
  const store = useStore();
  const routePush = useLocalizedRoute();

  const unwatch = store.watch(
    (state) => state.user,
    (user) => {
      if (user) {
        routePush(redirect_path);
      }
    },
    { immediate: true },
  );
  onUnmounted(() => {
    unwatch();
  });
};

export const requireLogin = (redirect_path: string) => {
  const store = useStore();
  const routePush = useLocalizedRoute();

  const unwatch = store.watch(
    (state) => state.user,
    (user) => {
      if (user === null) {
        routePush(redirect_path);
      }
    },
    { immediate: true },
  );
  onUnmounted(() => {
    unwatch();
  });
};

export const useDetacher = (name?: string) => {
  const detachers: Unsubscribe[] = [];
  const detach = () => {
    console.log("detach: " + (name || ""));
    detachers.map((d) => {
      d();
    });
    detachers.length = 0;
  };
  onUnmounted(() => {
    detach();
  });
  return {
    detachers,
    detach,
  };
};

export const arrayChunk = <T>(arr: T[], size = 1) => {
  const array = [...arr];
  return array.reduce((current: T[][], value: T, index: number) => {
    return index % size ? current : [...current, array.slice(index, index + size)];
  }, []);
};

export const setPageTitle = (title: string) => {
  const site_name = ["InstaGraph"];
  document.title = [title, site_name].join(" / ");
};

export const arrayUniq = <T>(arr: T[]): T[] => {
  return [...new Set(arr)];
};

export const resizeEndEventListener = (func: () => void) => {
  let doit: number | NodeJS.Timeout = 0;
  const resizedHandler = () => {
    clearTimeout(doit);
    doit = setTimeout(() => {
      func();
    }, 500);
  };
  onMounted(() => {
    window.addEventListener("resize", resizedHandler);
  });
  onUnmounted(() => {
    window.removeEventListener("resize", resizedHandler);
  });
};

export const useToggle = (defaultValue = false) => {
  const value = ref(defaultValue);
  const toggleOn = () => {
    value.value = true;
  };
  const toggleOff = () => {
    value.value = false;
  };
  const toggle = () => {
    value.value = !value.value;
  };
  return {
    value,
    toggleOn,
    toggleOff,
    toggle,
  };
};

export const getBrowserLocales = () => {
  const locale = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || "en";
  const short_locale = locale.split("_")[0];
  return {
    locale,
    short_locale,
  };
};
