import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import "./index.css";
import router from "./router";
import store from "./store";

import i18nConf from "./i18n/index";
import { i18nUtils } from "./i18n/utils";

import * as Sentry from "@sentry/vue";
import { sentryConfig } from "@/config/project";

const i18n = createI18n(i18nConf);

const app = createApp(App);
const head = createHead();

app.use(store);
app.use(router);
app.use(i18n);
app.use(i18nUtils);
app.use(head);

if (location.hostname !== "localhost") {
  Sentry.init({
    app,
    dsn: sentryConfig.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: sentryConfig.tracePropagationTargets,
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.mount("#app");
