<template>
  <div>
    <div class="w-4/5 bg-white rounded-md mt-4 mx-auto h-screen mb-12">
      <div class="text-2xl">User Config</div>
      <form @submit.prevent="handleFormSubmit">
        <div class="mx-8">
          <div class="w-full text-left">Nickname</div>
          <div class="w-full text-left my-2">
            <input type="text" v-model="user.nickname" class="flex-grow p-2 border rounded-md" :disabled="loading" />
          </div>
          <div class="w-full text-left my-2">Profile</div>
          <div class="w-full text-left">
            <textarea class="w-full max-w-[400px] h-24 border-2" v-model="user.profile" :disabled="loading"></textarea>
          </div>
          <div>
            <input type="submit" value="Submit" class="py-2 px-4 text-white rounded cursor-pointer bg-blue-500 mt-4" :disabled="loading" />
          </div>
          <div class="text-red-600 font-bold" v-if="saved">Saved</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { useUser, requireLogin, sleep } from "@/utils/utils";

import { UserDocumentData } from "@/models/user";

import { db } from "@/utils/firebase";
import { getDoc, setDoc, doc, serverTimestamp } from "firebase/firestore";

export default defineComponent({
  setup() {
    const loading = ref(true);
    const saved = ref(false);
    const userData = useUser();
    const user = ref({ nickname: "", profile: "" });
    const userPath = computed(() => {
      return `user/${userData.value.uid}`;
    });
    requireLogin("/");

    const load_user = async () => {
      const userDoc = await getDoc(doc(db, userPath.value));
      if (userDoc) {
        const data = userDoc.data() as UserDocumentData;
        user.value = data || { nickname: "", profile: "" };
      }
    };
    watch(
      userData,
      (v) => {
        if (v) {
          loading.value = false;
          load_user();
        }
      },
      { immediate: true },
    );

    const handleFormSubmit = async () => {
      loading.value = true;
      await setDoc(doc(db, userPath.value), { nickname: user.value.nickname, profile: user.value.profile, updated_at: serverTimestamp() }, { merge: true });
      loading.value = false;
      saved.value = true;
      await sleep(500);
      saved.value = false;
    };

    return {
      user,
      handleFormSubmit,
      loading,
      saved,
    };
  },
});
</script>
